// @flow

import * as React from 'react';
import Route from 'react-router-dom/Route';
import Switch from 'react-router-dom/Switch';
import Redirect from 'react-router/Redirect';

import { Context } from '../../_helpers/Identity';
import { routerHistory } from '.';

export type RouteConf = {
  component?: React$ComponentType<RouteProps<*>>,
  routes?: Array<RouteConf>,
  permission?: string,
  exact?: boolean,
  path: string
};

class RenderRoute extends React.PureComponent<RouteConf> {
  static contextType = Context;

  render(): React.Node {
    const { routes, permission, ...routeProps } = this.props;
    if (
      permission &&
      (!this.context.identity ||
        !this.context.identity.permissions ||
        this.context.identity.permissions.indexOf(permission) === -1)
    ) {
      routerHistory.rememberUnauthorized(window.location.pathname);
      return <Redirect to="/auth/login" />; // !! This route MUST be public, or you will get infinite redirect loop
    }

    return routes && routes.length ? (
      renderRoutes([...routes, { ...routeProps, routes: undefined, path: '' }], routeProps.path)
    ) : (
      <Route {...routeProps} />
    );
  }
}

export default function renderRoutes(routes: Array<RouteConf>, parent?: string): React.Node {
  if (!routes || !routes.length) {
    return null;
  }

  return routes.length > 1 ? (
    <Switch>
      {routes.map((routeConf: RouteConf) => (
        <RenderRoute key={routeConf.path} {...routeConf} path={(parent || '') + routeConf.path} />
      ))}
    </Switch>
  ) : (
    <Route {...routes[0]} />
  );
}
