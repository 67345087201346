// @flow

import * as React from 'react';
import I18n from '_helpers/I18n';
import Redirect from 'react-router-dom/Redirect';

import toastr from '../../_helpers/toastr';

// -------------------------------------------------------------------------------------------------

export default function VerifiedPage(props: *): React.Node {
  React.useEffect(() => {
    toastr({
      message: <I18n id="emailHasBeenVerified" d="Great! Your email has been verified." />,
      type: 'success'
    });
  });

  return <Redirect to="/auth/login" />;
}
