// @flow

import * as React from 'react';
import Navbar from 'react-bootstrap/Navbar';

import Form from '../../../_components/Form';
import CooperationPicker from '../CooperationPicker';
import CategoryKeywordAutocomplete from '../CategoryKeywordAutocomplete';

export default function SearchDealsFilter(): React.Node {
  return (
    <div>
      <div className="filter-panel">
        <Navbar className="inline" variant="light" bg="light">
          <Form.Field component={CooperationPicker} id="cooperationPicker" name="cooperation" />
        </Navbar>
        <Navbar className="inline" variant="light" bg="light">
          {/* $FlowFixMe */}
          <Form.Field
            style={{ paddingTop: 7, paddingBottom: 8 }}
            component={CategoryKeywordAutocomplete}
            id="filter-panel.categoryOrKeyword"
            name="categoryOrKeyword"
            block
          />
        </Navbar>
      </div>
      <div className="pb-5" />
    </div>
  );
}
