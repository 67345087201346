// @flow

import idx from 'idx';
import I18n from '_helpers/I18n';
import * as React from 'react';
import { graphql } from 'react-relay';

import type { CompanyFormData } from './_components/CompanyForm';

import toastr from '../../_helpers/toastr';
import Layout from '../../_components/Layout';
import CompanyForm from './_components/CompanyForm';
import LoadingContent from '../_components/LoadingContent';
import { Query, commitMutationPromise } from '../../_helpers/Api';
import { getQueryParam, routerHistory } from '../../_helpers/router';

// -------------------------------------------------------------------------------------------------

export default function AccountCompanyPage(props: *): React.Node {
  const [pending, setPending] = React.useState(false);

  return (
    <Layout.Common narrow>
      <h1 className="mb-4 mt-4">
        <I18n id="title" d="My company" />
      </h1>
      <Query name="myCompany">
        {({ loading, error, data }) =>
          loading ? (
            <LoadingContent /> // TODO: form default walue was set -> reset default now its disabled
          ) : (
            <CompanyForm
              defaultValue={idx(data, _ => _.me.company) || {}}
              onSubmit={submitHandler(setPending)}
              disabled={loading || pending}
            />
          )
        }
      </Query>
    </Layout.Common>
  );
}

// -------------------------------------------------------------------------------------------------

function submitHandler(setPending: boolean => void): (null | CompanyFormData) => void {
  return function handleCompanyFormSubmit(data: null | CompanyFormData): void {
    if (data) {
      setPending(true);

      const nextStep = getQueryParam('next-step');

      commitMutationPromise({
        mutation: updateCompanyMutation,
        variables: {
          data: {
            ...data,
            web: data.web && !hasProtocol(data.web) ? 'http://' + data.web : data.web || null,
            categories: data.categories ? data.categories.map(i => i.id) : [],
            state: data.country === 'US' ? data.state : null,
            vat: (data.vat || '').toUpperCase() || null,
            logo: idx(data, _ => _.logo.id) || null,
            id: undefined,
            gps: {
              lng: 1,
              lat: 1,
              ...data.gps
            }
          }
        }
      })
        .then(response => ({ response, error: undefined }))
        .catch(error => ({ error }))
        .then(({ error }) => {
          setPending(false);

          if (nextStep) {
            routerHistory.push(nextStep);
          }

          toastr({
            type: error ? 'error' : 'success',
            message: error ? (
              <I18n id="mutationError" d="Ooops! Company details not saved" />
            ) : (
              <I18n id="mutationSuccess" d="Company details saved" />
            )
          });
        });
    }
  };
}

const hasProtocol = val =>
  typeof val === 'string' && (val.startsWith('http://') || val.startsWith('https://'));

// -------------------------------------------------------------------------------------------------

// TODO: use fragment
const updateCompanyMutation = graphql`
  mutation AccountCompanyPageMutation($data: CompanyInput!) {
    myCompany: updateMyCompany(data: $data) {
      id
      country
      state
      street
      city
      zip
      gps {
        lng
        lat
      }
      name
      vat
      logo {
        id
        size
        name
        url
      }
      web
      info
      specialization
      populated
      certificates
      categories {
        id
        name
      }
      created
      modified
      populated
    }
  }
`;
