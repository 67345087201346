// @flow

import * as React from 'react';
import Button from 'react-bootstrap/Button';
import Jumbo from 'react-bootstrap/Jumbotron';
import Container from 'react-bootstrap/Container';

import I18n from '_helpers/I18n';
import Link from '../../../_components/Link';
import BBCode from '../../../_components/BBCode';

import van from '../../../_assets/images/pin_van.jpg';
import traktor from '../../../_assets/images/pin_traktor.jpg';

// Hompage banner with video
export default function Section1(): React.Node {
  return (
    <Jumbo className="home-s-1">
      <Container>
        <h1 className="mb-0 mt-5">
          <I18n id="headline" d='[span class="text-primary"]B3B[/span] cooperation[br /]made easy'>
            {({ value }) => <BBCode>{value}</BBCode>}
          </I18n>
        </h1>
        <p className="text-muted text-center mt-5 mb-5">
          <I18n
            d="Utilize our database of Opportunities and contracts to find[br /] the right partner for your needs"
            id="subtitle"
          >
            {({ value }) => <BBCode>{value}</BBCode>}
          </I18n>
        </p>
        <div className="d-block text-center mb-5">
          <Link
            className="mb-3 mb-md-0 mr-0 mr-md-3 d-block d-md-inline-block text-uppercase"
            to="/auth/sign-up"
            component={Button}
          >
            <I18n id="tryButton" d="Try now for free" />
          </Link>
          <Link
            className="mb-5 mb-md-0 d-block d-md-inline-block text-uppercase"
            to="/#why-b3b"
            component={Button}
            variant="dark"
          >
            <I18n id="successButton" d="Learn more" />
          </Link>
        </div>
      </Container>
      <div className="home-s-1-img">
        <Pin left={0.24} bottom={0.61} img={van} size={72} label={1} />
        <Pin left={0.4} bottom={0.2} img={traktor} size={92} label={2} />
        <Pin left={0.702} bottom={0.145} img={van} size={72} label={3} />
        <Pin left={0.785} bottom={0.4} img={traktor} size={92} label={4} />
      </div>
    </Jumbo>
  );
}

function Pin(props: {
  img: string,
  left: number,
  size: number,
  bottom: number,
  label: string | number
}): React.Node {
  const x = Number(Math.sqrt((props.size * props.size) / 2).toFixed(0));
  return (
    <div
      style={{
        bottom: (props.bottom * 100).toFixed(2) + '%',
        left: (props.left * 100).toFixed(2) + '%',
        marginLeft: (props.size / -2) | 0,
        width: props.size
      }}
      className="home-s-1-pin"
    >
      <span style={{ width: props.size, height: props.size }}>
        <i style={{ top: x / 4, right: x / 4 }}>{props.label}</i>
        <span style={{ height: x, width: x }}>
          <span
            style={{
              backgroundImage: `url('${props.img}')`,
              height: props.size,
              width: props.size
            }}
          />
        </span>
      </span>
      <i />
      <i />
    </div>
  );
}
