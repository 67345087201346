// @flow

import cn from 'classnames';
import * as React from 'react';
import I18n from '_helpers/I18n';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import Container from 'react-bootstrap/Container';
import DropdownButton from 'react-bootstrap/DropdownButton';

import Link from '../Link';
import Icon from '../Icon';
import Brand from '../Brand';
import menuItems from './config';
import LocaleSwitch from '../LocaleSwitch';
import { Context } from '../../_helpers/Identity';

// -------------------------------------------------------------------------------------------------

export type MenuState = {
  open: boolean
};

export default class Menu extends React.PureComponent<*, MenuState> {
  static contextType = Context;
  state = { open: false };

  render(): React.Node {
    const open = this.state.open;
    const hamburgerClass = cn('hamburger hamburger--collapse', { 'is-active': open });
    return (
      <>
        <div id="site-menu-placeholder" />
        <Navbar className={open ? 'open' : ''} id="site-menu" expand="lg" fixed="top">
          <Container>
            <Navbar.Brand href="/" className="mr-auto p-0">
              <Brand size={96} />
            </Navbar.Brand>

            <Nav className="d-inline d-lg-none">
              <Nav.Item className="d-flex align-items-center">
                <LocaleSwitch />
              </Nav.Item>
            </Nav>

            <div className="d-none d-lg-inline-block">
              <MainNav handleToggle={this.handleToggle} />
            </div>

            <Navbar.Toggle>
              <div className="burger">
                <div
                  className={hamburgerClass}
                  onClick={this.handleToggle}
                  aria-controls="navigation"
                  aria-label="Menu"
                  role="button"
                  tabIndex="0"
                >
                  <div className="hamburger-box">
                    <div className="hamburger-inner" />
                  </div>
                </div>
              </div>
            </Navbar.Toggle>
          </Container>

          <div className="d-block d-lg-none collapse-wrapper">
            <Navbar.Collapse in={open}>
              <div className="p-5">
                <MainNav handleToggle={this.handleToggle} />
              </div>
            </Navbar.Collapse>
          </div>
        </Navbar>
      </>
    );
  }

  handleToggle = (e: SyntheticEvent<HTMLButtonElement>): void => {
    this.setState(s => ({ open: window.innerWidth > 991 ? false : !s.open }));
  };

  componentDidMount() {
    window.addEventListener('click', this.handleWindowClick, false);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleWindowClick, false);
  }

  handleWindowClick = (e: SyntheticMouseEvent<HTMLElement>): void => {
    const elem = document.getElementById('site-menu');

    if (this.state.open && elem) {
      const height = elem.clientHeight;
      if (e.clientY >= height) {
        this.setState({ open: false });
      }
    }
  };
}

function MainNav(props: {
  className?: string,
  handleToggle: (SyntheticEvent<HTMLButtonElement>) => void
}): React.Node {
  const context = React.useContext(Context);
  return (
    <Nav className={'ml-0 ml-md-auto' + (props.className || '')}>
      {menuItems[context.identity ? 'user' : 'anonym'].map((it, key) => (
        // TODO: key
        <Nav.Item key={key}>
          <Link
            className="primary-menu-item mb-3 mb-sm-0"
            onClick={props.handleToggle}
            component={Nav.Link}
            to={it.path}
          >
            {it.label}
          </Link>
        </Nav.Item>
      ))}

      <Nav.Item className="ml-5 align-items-center d-none d-lg-flex">
        <LocaleSwitch />
      </Nav.Item>
      {context.identity ? (
        <>
          <Nav.Item className="d-none d-lg-flex align-items-center ml-2">
            <DropdownButton
              title={<Icon name="user" className="mr-1" />}
              variant="outline-primary"
              className="btn-icon"
              id="d-flex"
              alignRight
            >
              {menuItems.profile.map((it, key) => (
                // TODO: key
                <Link component={Dropdown.Item} to={it.path} key={key} onClick={props.handleToggle}>
                  {it.label}
                </Link>
              ))}
            </DropdownButton>
          </Nav.Item>
          {menuItems.profile.map((it, key) => (
            // TODO: key
            <Nav.Item className="d-block d-lg-none" key={key}>
              <Link
                className="primary-menu-item mb-3 mb-sm-0"
                onClick={props.handleToggle}
                component={Nav.Link}
                to={it.path}
              >
                {it.label}
              </Link>
            </Nav.Item>
          ))}
        </>
      ) : (
        <div className="d-flex flex-column flex-md-row align-items-center ponny-here">
          <Nav.Item className="mb-4 mb-md-0">
            <Link
              className="d-flex align-items-center ml-2"
              onClick={props.handleToggle}
              variant="outline-primary"
              component={Button}
              to="/auth/login"
            >
              <Icon name="user" className="d-none d-sm-inline mr-1" />
              <I18n id="loginLink" d="Log in" />
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link
              className="d-flex align-items-center ml-2"
              onClick={props.handleToggle}
              component={Button}
              to="/auth/sign-up"
            >
              <I18n id="joinButton" d="Join" />
            </Link>
          </Nav.Item>
        </div>
      )}
    </Nav>
  );
}
