// @flow

import * as React from 'react';

import isValid from './Validator';
import validateForm from './validateFormHelper';
import ValidationMessage from './ValidationMessage';

import type { FieldError } from './types';
import type { ValidationRules } from './Validator';

// -------------------------------------------------------------------------------------------------

const validateField = (
  name: ?string,
  rules: ValidationRules<*>,
  value: any,
  values: any
): null | FieldError | { [string]: FieldError } => {
  if (rules.hasOwnProperty('nested')) {
    let validateRules = rules.nested;
    if (name) {
      const target = name.substr(name.indexOf('.') + 1);
      validateRules = rules.nested[target] ? { [target]: rules.nested[target] } : null;
    } else {
      validateRules = rules.nested;
    }
    return validateRules && validateForm(validateRules, value);
  }

  const keys = Object.keys(rules);
  let i = 0;

  while (i < keys.length) {
    const code: string = keys[i++];

    if (!isValid(code, value, rules[code], values)) {
      const err: FieldError = {
        // TODO: remove jsx
        message: (
          <ValidationMessage
            error={{
              options: rules[code],
              value,
              code
            }}
          />
        ),
        options: rules[code],
        value,
        code
      };
      return err;
    }
  }
  return null;
};

export default validateField;
