// @flow

import * as React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import Logger from '@app/Logger';
import * as serviceWorker from './serviceWorker';

import './index.scss';
import packageJson from '../package.json';

// -------------------------------------------------------------------------------------------------

// define app Version
let version =
  typeof packageJson.version === 'string' && packageJson.version.length > 4
    ? packageJson.version
    : '0.0.0';

// prod = package json, dev = commit number
version = version.split('.');
version[2] = process.env.REACT_APP_VERSION || '0';
version = version.join('.');

// $FlowFixMe
Object.defineProperty(window, 'version', {
  get: function() {
    return version;
  },
  set: function(y) {
    return version;
  }
});

// -------------------------------------------------------------------------------------------------

const root = document.getElementById('root');

Logger.init();

root && ReactDOM.render(<App />, root);

serviceWorker.unregister();
