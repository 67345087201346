// @flow

import * as React from 'react';

import Country from './Country';
import USState from './USState';
import DealType from './DealType';
import DealStatus from './DealStatus';
import Salutation from './Salutation';
import Cooperation from './Cooperation';
import MessagingType from './MessagingType';
import ContractStatus from './ContractStatus';

export type EnumChildren = ({ value: string, d: * }) => React.Node;

const Enum = {
  ContractStatus,
  MessagingType,
  Cooperation,
  Salutation,
  DealStatus,
  DealType,
  USState,
  Country
};

export default Enum;
