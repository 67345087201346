// @flow

import idx from 'idx';
import * as React from 'react';
import I18n from '_helpers/I18n';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import { Query } from '_helpers/Api';
import BBCode from '../../_components/BBCode';
import Layout from '../../_components/Layout';
import { Context as Identity } from '_helpers/Identity';
import LoadingContent from '../_components/LoadingContent';

export default function PaymentSuccess(): React.Node {
  const { loading: refreshing, refreshIdentity, identity } = React.useContext(Identity);

  React.useEffect(() => {
    !refreshing && idx(identity, _ => _.company.paying) === false && refreshIdentity();
  }, [identity, refreshIdentity]);

  return (
    <Layout verticalCenter>
      <Row className="mt-5 mb-5 d-flex justify-content-center align-items-center">
        <Col lg={4} sm={8} xs={12} className="text-center">
          <Query name="me">
            {({ loading }) =>
              loading || refreshing ? (
                <LoadingContent />
              ) : (
                <Card className="p-5">
                  <h1 className="mb-4 mt-4">
                    <I18n id="title" d="Well done!" />
                  </h1>
                  <p className="text-center">
                    <I18n
                      d='Your payment has been[br /][span class="p-1 bg-success"]confirmed[/span][br /][b]Thank you![/b]'
                      id="message"
                    >
                      {({ value }) => <BBCode>{value}</BBCode>}
                    </I18n>
                  </p>
                  <p className="text-center mt-3">
                    <I18n
                      d='Continue with searching[br /][a href="/deal"]New opportunities[/a]'
                      id="moreText"
                    >
                      {({ value }) => <BBCode>{value}</BBCode>}
                    </I18n>
                  </p>
                </Card>
              )
            }
          </Query>
        </Col>
      </Row>
    </Layout>
  );
}
