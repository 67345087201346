// @flow

import cn from 'classnames';
import * as React from 'react';

import type { OnChangeEvent } from '../';

export default function TextInput(props: {
  onChange: (OnChangeEvent<string>) => void,
  placeholder?: React.Node,
  value: null | string,
  isInvalid?: boolean,
  className?: string,
  inputRef?: *,
  name: string,
  error?: *
}): React.Node {
  const {
    onChange,
    placeholder,
    error, // eslint-disable-line
    value,
    inputRef,
    name,
    className,
    isInvalid,
    ...rest
  } = props;

  // $FlowFixMe
  delete rest.isValid;
  // $FlowFixMe
  delete rest.validationRules;
  // $FlowFixMe
  delete rest.batchChange;

  return (
    <input
      {...rest}
      className={cn('form-control', { 'is-invalid': isInvalid }, className || '')}
      onChange={changeHandler(name, onChange)}
      placeholder={placeholder}
      value={value || ''}
      ref={inputRef}
      name={name}
    />
  );
}

function changeHandler(
  name: string,
  onChange: (OnChangeEvent<string>) => void
): (SyntheticEvent<HTMLTextAreaElement>) => void {
  return function(e: SyntheticEvent<HTMLTextAreaElement>): void {
    const value: null | string = e.currentTarget.value || null;
    onChange && onChange({ value, name });
  };
}
