// @flow

import I18n from '_helpers/I18n';
import * as React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { graphql } from 'react-relay';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import type { DealsFilterType } from './_components/SearchResults';

import Icon from '../_components/Icon';
import Layout from '../_components/Layout';
import Searchresults from './_components/SearchResults';
import Form, { FormContext } from '../_components/Form';
import LocationFilterInput from './_components/LocationFilterInput';
import MyLocationFakeInput from './_components/MyLocationFakeInput';
import { parseFilter, getFilterUrlAction } from './_helpers/dealUrlFilter';

// -------------------------------------------------------------------------------------------------

const MAX_RADIUS = 201;

const defaultFilter: DealsFilterType = {
  location: {
    place: null,
    radius: MAX_RADIUS,
    ignore: true,
    open: false
  }
};

export { defaultFilter };

// -------------------------------------------------------------------------------------------------

const updateFilterUrl = getFilterUrlAction('/deal/suggested');

export default class SuggestedDealsPage extends React.PureComponent<*> {
  initialFilter = parseFilter('');

  constructor(props: *) {
    super(props);
    this.initialFilter = parseFilter('?' + (window.location.search || ''));
  }

  render(): React.Node {
    return (
      <Layout.Common>
        <Form defaultValue={this.initialFilter} didChange={updateFilterUrl}>
          <Form.Field component={MyLocationFakeInput} name="location.place" />
          <Row className="deal-filter-header mb-4 mt-4">
            <Col className="d-md-flex align-items-center justify-content-between">
              <h1>
                <I18n id="title" d="Suggested opportunities" />
              </h1>
              <div className="float-sm-right">
                <Form.Field name="location.open" component={OpenMapButton} />
              </div>
            </Col>
          </Row>

          <Form.Field component={LocationFilterInput} max={MAX_RADIUS} step={5} name="location" />

          <Searchresults
            filterDecorator={decorateFilter}
            contexRef={FormContext} // Connected to form context
            query={suggestedQuery}
            noResults={NoResults}
            suggested
          />
        </Form>
      </Layout.Common>
    );
  }
}

// -------------------------------------------------------------------------------------------------

function NoResults() {
  return <I18n d="No opportunities were found." id="noResultsPlaceholder" />;
}

// -------------------------------------------------------------------------------------------------

// Decorate Form schema to query params
function decorateFilter(data: *): DealsFilterType {
  const location = data.location || {};

  const filter = {
    location: location.radius === MAX_RADIUS ? {} : location
  };

  return filter;
}

// -------------------------------------------------------------------------------------------------

function OpenMapButton(props: *): React.Node {
  const { onChange, value, values, name } = props;

  const onClick = React.useCallback(() => {
    onChange({ name, value: !value });
  }, [name, value, onChange]);

  const c = values.location.ignore ? 'gray' : 'success';
  const open = value;

  return (
    <ButtonGroup>
      <Button
        variant={open ? c : 'outline-' + c}
        className="d-flex filter-button"
        onClick={onClick}
        size="sm"
      >
        <Icon name="location" className="mr-1 sole-icon" />
        {open ? (
          <I18n id="closeMabButton" d="Close map" />
        ) : (
          <I18n id="mapFilterButton" d="Open map" />
        )}
        <Icon name={open ? 'chevron-up' : 'chevron-down'} className="ml-3 sole-icon" />
      </Button>
    </ButtonGroup>
  );
}

// -------------------------------------------------------------------------------------------------

const suggestedQuery = graphql`
  query SuggestedDealsPageQuery($first: Int!, $offset: Int, $location: LocationInput) {
    search {
      results: suggestedDeals(first: $first, offset: $offset, location: $location)
        @connection(key: "SuggestedDeals_results", filters: []) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            summary
            type
            cooperation
            status
            category {
              id
              name
            }
            keywords
            created
            description
            unseenContracts: contracts(seen: false) {
              total
            }
          }
        }
        total
      }
    }
  }
`;
