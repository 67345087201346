// @flow

import type { ButtonProps } from 'react-bootstrap/Button';

import * as React from 'react';
import Button from 'react-bootstrap/Button';
import Icon from './Icon';

type Props = ButtonProps & { icon: string };

export default function IconButton(props: Props): React.Node {
  const { icon, className, ...passing } = props;
  return (
    <Button className={'btn-icon' + (className ? ' ' + className : '')} {...passing}>
      <Icon name={icon} />
    </Button>
  );
}
