// @flow

import I18n from '_helpers/I18n';
import * as React from 'react';
import { graphql } from 'react-relay';
import Button from 'react-bootstrap/Button';
import Redirect from 'react-router-dom/Redirect';

import type { DealFormData } from './_components/DealForm';

import Logger from '@app/Logger';
import Link from '../../_components/Link';
import Icon from '../../_components/Icon';
import toastr from '../../_helpers/toastr';
import { Query } from '../../_helpers/Api';
import Layout from '../../_components/Layout';
import DealForm from './_components/DealForm';
import { routerHistory } from '../../_helpers/router';
import { commitMutationPromise } from '../../_helpers/Api';
import LoadingContent from '../_components/LoadingContent';
import { decorateDealInput } from './_helpers/decorateDealInput';
import { Context as IdentityContext } from '../../_helpers/Identity';

// -------------------------------------------------------------------------------------------------

export type EditDealPageProps = RouteProps<{ dealId: ID }>;

// -------------------------------------------------------------------------------------------------

export default function EditDealPage(props: EditDealPageProps): React.Node {
  const dealId = props.match.params.dealId;
  const [pending, setPending] = React.useState(false);
  const { identity } = React.useContext(IdentityContext);

  return (
    <Layout.Common narrow>
      <div className="mb-4 mt-4 d-flex align-items-center">
        <h1 className="d-block d-md-inline-block">
          <I18n id="title" d="Edit Opportunity" />
        </h1>
        {dealId && (
          <span className="ml-5">
            <Link to={`/deal/manage/${dealId}`}>
              <Icon name="clipboard-list" className="mr-2" />
              <I18n id="manage" d="Business Partners" />
            </Link>
          </span>
        )}
      </div>

      <Query name="dealDetail" variables={{ dealId }}>
        {({ loading, error, data }) => {
          if (loading) {
            return <LoadingContent />;
          }

          if (error) {
            Logger.error(error);
            return <Redirect to="/error/500" />;
          }

          const deal = data.deal;

          if (!data || !data.deal) {
            Logger.error(`EditDealPage: Opportunity "${dealId}" not foud`);
            return <Redirect to="/error/500" />;
          }

          // eslint-disable-next-line
          if (!identity || deal.company.id != identity.company.id) {
            return <Redirect to="/error/403" />;
          }

          return (
            <DealForm
              onSubmit={handleSubmit(dealId, setPending, pending)}
              defaultValue={deal}
              disabled={pending}
            >
              <>
                <Button
                  className="d-block d-md-inline mb-3 mb-md-0 mr-0 mr-md-3"
                  disabled={pending}
                  type="submit"
                >
                  <I18n id="submitButton" d="Update Opportunity" />
                </Button>

                <Link
                  variant="outline-dark"
                  to="/account/my-deals"
                  disabled={pending}
                  component={Button}
                >
                  <I18n id="cancelButton" d="Discard changes" />
                </Link>
              </>
            </DealForm>
          );
        }}
      </Query>
    </Layout.Common>
  );
}

// -------------------------------------------------------------------------------------------------

// TODO: move submit method to form -> create async / promise action which returns {response, errors}
function handleSubmit(
  dealId: ID,
  setPending: boolean => void,
  pending: boolean
): (null | DealFormData) => void {
  return function(data: null | DealFormData): void {
    const inputData = data && decorateDealInput(data);

    if (!inputData || pending) {
      return;
    }

    setPending(true);
    // TODO: decorate data
    commitMutationPromise({
      mutation: editDealMutation,
      variables: {
        data: inputData,
        dealId
      }
    })
      .then(response => ({ response, error: undefined }))
      .catch(error => ({ error }))
      .then(({ error }) => {
        setPending(false);
        toastr({
          type: error ? 'error' : 'success',
          message: error ? (
            <I18n id="responseError" d="Opportunity details was not saved!" />
          ) : (
            <I18n id="responseSuceess" d="Opportunity saved" />
          )
        });
        !error && routerHistory.push('/account/my-deals');
      });
  };
}

// -------------------------------------------------------------------------------------------------

// TODO: use fragment
const editDealMutation = graphql`
  mutation EditDealPageMutation($dealId: ID!, $data: DealInput!) {
    deal: updateDeal(id: $dealId, data: $data) {
      id
      status
      cooperation
      type
      summary
      category {
        id
        name
      }
      expires
      description
      keywords
      partnerRequirements
      attachments {
        id
        name
        url
      }
      video
      created
      modified
      company {
        id
      }
    }
  }
`;
