// @flow

import idx from 'idx';
import * as React from 'react';

import type { OnChangeEvent } from '../../_components/Form';
import type { TypeaheadOptions } from '../../_components/Form/_components/Typeahead';

import { Query } from '../../_helpers/Api';
import { Typeahead } from '../../_components/Form';

// -------------------------------------------------------------------------------------------------

export type KeywordsAutocompleteProps = {
  onChange: (OnChangeEvent<NACECategory>) => void,
  value: null | Array<string>,
  name: string,
  id?: string
};

// -------------------------------------------------------------------------------------------------

export default function KeywordsAutocomplete(props: KeywordsAutocompleteProps): React.Node {
  const [search, setSearch] = React.useState('');
  const { value, onChange, name, id, ...rest } = props;

  return (
    <Query name="searchKeywords" variables={{ search }}>
      {({ loading, data }) => (
        <Typeahead
          {...rest}
          value={value ? value.map(i => ({ value: i, label: i })) : null}
          id={id || 'search-category-' + Date.now()}
          // $FlowFixMe
          onChange={onChangeHandler(onChange, name)}
          options={decorateOptions(search, data)}
          className="control-search"
          onSearch={setSearch}
          loading={loading}
          name={name}
          multiple
        />
      )}
    </Query>
  );
}

// -------------------------------------------------------------------------------------------------

function onChangeHandler(
  onChange: (OnChangeEvent<Array<string>>) => void,
  name: string
): (OnChangeEvent<*>) => void {
  return function handleOnChange(event: OnChangeEvent<*>): void {
    const { value, name } = event;
    const val = value ? value.map(i => i.value).filter(i => i) : null;
    onChange({ name, value: val });
  };
}

function decorateOptions(search: string, data: *): TypeaheadOptions {
  return [
    { value: search, label: search },
    ...(idx(data, _ => _.search.keywords) || []).map(i => ({ value: i, label: i }))
  ];
}
