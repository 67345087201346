/**
 * @flow
 * @relayHash 0ac746a46511b37855ab3ee4f619fa86
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DealCooperation = "RND_COOPERATION" | "SHARE_ASSETS" | "SHARE_PROCUREMENT" | "SPARE_CAPACITY" | "%future added value";
export type DealType = "OFFER" | "REQUEST" | "%future added value";
export type dealDetailQueryVariables = {|
  dealId: string
|};
export type dealDetailQueryResponse = {|
  +deal: ?{|
    +id: string,
    +summary: string,
    +type: DealType,
    +cooperation: DealCooperation,
    +category: {|
      +id: string,
      +name: string,
    |},
    +description: string,
    +keywords: $ReadOnlyArray<any>,
    +expires: ?any,
    +partnerRequirements: ?string,
    +video: ?string,
    +attachments: $ReadOnlyArray<{|
      +id: string,
      +url: string,
      +name: string,
      +size: number,
    |}>,
    +company: {|
      +id: string
    |},
  |}
|};
export type dealDetailQuery = {|
  variables: dealDetailQueryVariables,
  response: dealDetailQueryResponse,
|};
*/

/*
query dealDetailQuery(
  $dealId: ID!
) {
  deal(id: $dealId) {
    id
    summary
    type
    cooperation
    category {
      id
      name
    }
    description
    keywords
    expires
    partnerRequirements
    video
    attachments {
      id
      url
      name
      size
    }
    company {
      id
    }
  }
}
*/

const node /*: ConcreteRequest*/ = (function() {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'dealId',
        type: 'ID!',
        defaultValue: null
      }
    ],
    v1 = {
      kind: 'ScalarField',
      alias: null,
      name: 'id',
      args: null,
      storageKey: null
    },
    v2 = {
      kind: 'ScalarField',
      alias: null,
      name: 'name',
      args: null,
      storageKey: null
    },
    v3 = [
      {
        kind: 'LinkedField',
        alias: null,
        name: 'deal',
        storageKey: null,
        args: [
          {
            kind: 'Variable',
            name: 'id',
            variableName: 'dealId'
          }
        ],
        concreteType: 'Deal',
        plural: false,
        selections: [
          (v1 /*: any*/),
          {
            kind: 'ScalarField',
            alias: null,
            name: 'summary',
            args: null,
            storageKey: null
          },
          {
            kind: 'ScalarField',
            alias: null,
            name: 'type',
            args: null,
            storageKey: null
          },
          {
            kind: 'ScalarField',
            alias: null,
            name: 'cooperation',
            args: null,
            storageKey: null
          },
          {
            kind: 'LinkedField',
            alias: null,
            name: 'category',
            storageKey: null,
            args: null,
            concreteType: 'NACECategory',
            plural: false,
            selections: [(v1 /*: any*/), (v2 /*: any*/)]
          },
          {
            kind: 'ScalarField',
            alias: null,
            name: 'description',
            args: null,
            storageKey: null
          },
          {
            kind: 'ScalarField',
            alias: null,
            name: 'keywords',
            args: null,
            storageKey: null
          },
          {
            kind: 'ScalarField',
            alias: null,
            name: 'expires',
            args: null,
            storageKey: null
          },
          {
            kind: 'ScalarField',
            alias: null,
            name: 'partnerRequirements',
            args: null,
            storageKey: null
          },
          {
            kind: 'ScalarField',
            alias: null,
            name: 'video',
            args: null,
            storageKey: null
          },
          {
            kind: 'LinkedField',
            alias: null,
            name: 'attachments',
            storageKey: null,
            args: null,
            concreteType: 'File',
            plural: true,
            selections: [
              (v1 /*: any*/),
              {
                kind: 'ScalarField',
                alias: null,
                name: 'url',
                args: null,
                storageKey: null
              },
              (v2 /*: any*/),
              {
                kind: 'ScalarField',
                alias: null,
                name: 'size',
                args: null,
                storageKey: null
              }
            ]
          },
          {
            kind: 'LinkedField',
            alias: null,
            name: 'company',
            storageKey: null,
            args: null,
            concreteType: 'Company',
            plural: false,
            selections: [(v1 /*: any*/)]
          }
        ]
      }
    ];
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'dealDetailQuery',
      type: 'Query',
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: (v3 /*: any*/)
    },
    operation: {
      kind: 'Operation',
      name: 'dealDetailQuery',
      argumentDefinitions: (v0 /*: any*/),
      selections: (v3 /*: any*/)
    },
    params: {
      operationKind: 'query',
      name: 'dealDetailQuery',
      id: null,
      text:
        'query dealDetailQuery(\n  $dealId: ID!\n) {\n  deal(id: $dealId) {\n    id\n    summary\n    type\n    cooperation\n    category {\n      id\n      name\n    }\n    description\n    keywords\n    expires\n    partnerRequirements\n    video\n    attachments {\n      id\n      url\n      name\n      size\n    }\n    company {\n      id\n    }\n  }\n}\n',
      metadata: {}
    }
  };
})();
// prettier-ignore
(node/*: any*/).hash = 'abc66e2d18decb9b4e8493abcbfa4143';
module.exports = node;
