// @flow

import type { QueryRendererProps } from 'react-relay';

import * as React from 'react';
import { QueryRenderer, graphql } from 'react-relay';

import type { companyDetailQueryResponse } from './__generated__/companyDetailQuery.graphql';

import env from '../relayEnvironment';

export default function companyDetailQuery(
  props: QueryRendererProps<companyDetailQueryResponse>
): React.Node {
  return (
    <QueryRenderer
      variables={props.variables || {}}
      render={props.children}
      environment={env}
      query={graphql`
        query companyDetailQuery($companyId: ID!) {
          company(id: $companyId) {
            id
            name
            logo {
              id
              url
            }
            web
            info
            specialization
            certificates
            categories {
              id
              name
            }
            contact {
              firstName
              lastName
              phone
              email
              linkedin
              messaging {
                type
                value
              }
            }
            logo {
              id
              url
            }
            created
            succeededContracts
            totalContracts
            vat
            country
            state
            city
            street
            zip
            gps {
              lng
              lat
            }
            deals(open: true, first: 20) {
              edges {
                cursor
                node {
                  id
                  summary
                  type
                  cooperation
                  description
                  keywords
                  category {
                    id
                    name
                  }
                  summary
                  created
                }
              }
              total
              pageInfo {
                hasPreviousPage
                hasNextPage
                startCursor
                endCursor
              }
            }
          }
        }
      `}
    />
  );
}
