// @flow

import I18n from '_helpers/I18n';
import * as React from 'react';

import Enum from '../../_components/Enum';
import { ToggleInput } from '../../_components/Form';

// -------------------------------------------------------------------------------------------------

export default function CooperationPicker(props: *): React.Node {
  const { style, ...rest } = props;
  return (
    <Enum.Cooperation>
      {({ d }) => {
        const options = d;
        return (
          <>
            <ToggleInput
              {...rest}
              options={Object.keys(options).map(key => ({
                label: options[key],
                value: key
              }))}
              style={{
                boxShadow: 'none',
                padding: '.5rem',
                width: '100%',
                ...(style || {})
              }}
              label={
                <label className="text-secondary">
                  <I18n id="label" d="Cooperation Type" />
                </label>
              }
              className="cooperation-picker"
              multiple
            />
          </>
        );
      }}
    </Enum.Cooperation>
  );
}
