/**
 * @flow
 * @relayHash cd09ea2795f9a494fae181db7b09148a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type LangEnum = "CZ" | "EN" | "SK" | "%future added value";
export type MessagingProvider = "ICQ" | "SIGNAL" | "SKYPE" | "TELEGRAM" | "VIBER" | "WHATSAPP" | "%future added value";
export type Salutation = "MR" | "MS" | "%future added value";
export type meQueryVariables = {||};
export type meQueryResponse = {|
  +me: ?{|
    +id: string,
    +lang: ?LangEnum,
    +salutation: ?Salutation,
    +firstName: string,
    +lastName: string,
    +email: string,
    +contactEmail: ?string,
    +phone: ?string,
    +messaging: $ReadOnlyArray<{|
      +type: MessagingProvider,
      +value: string,
    |}>,
    +linkedin: ?string,
    +company: {|
      +id: string,
      +name: ?string,
      +paying: boolean,
      +deals: {|
        +total: number
      |},
      +populated: boolean,
      +subscription: ?{|
        +type: string,
        +created: any,
        +unsubscribed: ?any,
        +expires: any,
      |},
    |},
  |}
|};
export type meQuery = {|
  variables: meQueryVariables,
  response: meQueryResponse,
|};
*/

/*
query meQuery {
  me {
    id
    lang
    salutation
    firstName
    lastName
    email
    contactEmail
    phone
    messaging {
      type
      value
      id
    }
    linkedin
    company {
      id
      name
      paying
      deals(first: 1) {
        total
      }
      populated
      subscription {
        type
        created
        unsubscribed
        expires
        id
      }
    }
  }
}
*/

const node /*: ConcreteRequest*/ = (function() {
  var v0 = {
      kind: 'ScalarField',
      alias: null,
      name: 'id',
      args: null,
      storageKey: null
    },
    v1 = {
      kind: 'ScalarField',
      alias: null,
      name: 'lang',
      args: null,
      storageKey: null
    },
    v2 = {
      kind: 'ScalarField',
      alias: null,
      name: 'salutation',
      args: null,
      storageKey: null
    },
    v3 = {
      kind: 'ScalarField',
      alias: null,
      name: 'firstName',
      args: null,
      storageKey: null
    },
    v4 = {
      kind: 'ScalarField',
      alias: null,
      name: 'lastName',
      args: null,
      storageKey: null
    },
    v5 = {
      kind: 'ScalarField',
      alias: null,
      name: 'email',
      args: null,
      storageKey: null
    },
    v6 = {
      kind: 'ScalarField',
      alias: null,
      name: 'contactEmail',
      args: null,
      storageKey: null
    },
    v7 = {
      kind: 'ScalarField',
      alias: null,
      name: 'phone',
      args: null,
      storageKey: null
    },
    v8 = {
      kind: 'ScalarField',
      alias: null,
      name: 'type',
      args: null,
      storageKey: null
    },
    v9 = {
      kind: 'ScalarField',
      alias: null,
      name: 'value',
      args: null,
      storageKey: null
    },
    v10 = {
      kind: 'ScalarField',
      alias: null,
      name: 'linkedin',
      args: null,
      storageKey: null
    },
    v11 = {
      kind: 'ScalarField',
      alias: null,
      name: 'name',
      args: null,
      storageKey: null
    },
    v12 = {
      kind: 'ScalarField',
      alias: null,
      name: 'paying',
      args: null,
      storageKey: null
    },
    v13 = {
      kind: 'LinkedField',
      alias: null,
      name: 'deals',
      storageKey: 'deals(first:1)',
      args: [
        {
          kind: 'Literal',
          name: 'first',
          value: 1
        }
      ],
      concreteType: 'DealsConnection',
      plural: false,
      selections: [
        {
          kind: 'ScalarField',
          alias: null,
          name: 'total',
          args: null,
          storageKey: null
        }
      ]
    },
    v14 = {
      kind: 'ScalarField',
      alias: null,
      name: 'populated',
      args: null,
      storageKey: null
    },
    v15 = {
      kind: 'ScalarField',
      alias: null,
      name: 'created',
      args: null,
      storageKey: null
    },
    v16 = {
      kind: 'ScalarField',
      alias: null,
      name: 'unsubscribed',
      args: null,
      storageKey: null
    },
    v17 = {
      kind: 'ScalarField',
      alias: null,
      name: 'expires',
      args: null,
      storageKey: null
    };
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'meQuery',
      type: 'Query',
      metadata: null,
      argumentDefinitions: [],
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'me',
          storageKey: null,
          args: null,
          concreteType: 'User',
          plural: false,
          selections: [
            (v0 /*: any*/),
            (v1 /*: any*/),
            (v2 /*: any*/),
            (v3 /*: any*/),
            (v4 /*: any*/),
            (v5 /*: any*/),
            (v6 /*: any*/),
            (v7 /*: any*/),
            {
              kind: 'LinkedField',
              alias: null,
              name: 'messaging',
              storageKey: null,
              args: null,
              concreteType: 'MessageIdentity',
              plural: true,
              selections: [(v8 /*: any*/), (v9 /*: any*/)]
            },
            (v10 /*: any*/),
            {
              kind: 'LinkedField',
              alias: null,
              name: 'company',
              storageKey: null,
              args: null,
              concreteType: 'Company',
              plural: false,
              selections: [
                (v0 /*: any*/),
                (v11 /*: any*/),
                (v12 /*: any*/),
                (v13 /*: any*/),
                (v14 /*: any*/),
                {
                  kind: 'LinkedField',
                  alias: null,
                  name: 'subscription',
                  storageKey: null,
                  args: null,
                  concreteType: 'Subscription',
                  plural: false,
                  selections: [(v8 /*: any*/), (v15 /*: any*/), (v16 /*: any*/), (v17 /*: any*/)]
                }
              ]
            }
          ]
        }
      ]
    },
    operation: {
      kind: 'Operation',
      name: 'meQuery',
      argumentDefinitions: [],
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'me',
          storageKey: null,
          args: null,
          concreteType: 'User',
          plural: false,
          selections: [
            (v0 /*: any*/),
            (v1 /*: any*/),
            (v2 /*: any*/),
            (v3 /*: any*/),
            (v4 /*: any*/),
            (v5 /*: any*/),
            (v6 /*: any*/),
            (v7 /*: any*/),
            {
              kind: 'LinkedField',
              alias: null,
              name: 'messaging',
              storageKey: null,
              args: null,
              concreteType: 'MessageIdentity',
              plural: true,
              selections: [(v8 /*: any*/), (v9 /*: any*/), (v0 /*: any*/)]
            },
            (v10 /*: any*/),
            {
              kind: 'LinkedField',
              alias: null,
              name: 'company',
              storageKey: null,
              args: null,
              concreteType: 'Company',
              plural: false,
              selections: [
                (v0 /*: any*/),
                (v11 /*: any*/),
                (v12 /*: any*/),
                (v13 /*: any*/),
                (v14 /*: any*/),
                {
                  kind: 'LinkedField',
                  alias: null,
                  name: 'subscription',
                  storageKey: null,
                  args: null,
                  concreteType: 'Subscription',
                  plural: false,
                  selections: [
                    (v8 /*: any*/),
                    (v15 /*: any*/),
                    (v16 /*: any*/),
                    (v17 /*: any*/),
                    (v0 /*: any*/)
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    params: {
      operationKind: 'query',
      name: 'meQuery',
      id: null,
      text:
        'query meQuery {\n  me {\n    id\n    lang\n    salutation\n    firstName\n    lastName\n    email\n    contactEmail\n    phone\n    messaging {\n      type\n      value\n      id\n    }\n    linkedin\n    company {\n      id\n      name\n      paying\n      deals(first: 1) {\n        total\n      }\n      populated\n      subscription {\n        type\n        created\n        unsubscribed\n        expires\n        id\n      }\n    }\n  }\n}\n',
      metadata: {}
    }
  };
})();
// prettier-ignore
(node/*: any*/).hash = 'b2af229895314382e1dcf9c1b575b219';
module.exports = node;
