// @flow

import I18n from '_helpers/I18n';
import * as React from 'react';
import BForm from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import toastr from '_helpers/toastr';
import Layout from '_components/Layout';
import getCaptchaToken from '_helpers/getCaptchaToken';
import Form, { TextInput, TextareaInput } from '_components/Form';

// -------------------------------------------------------------------------------------------------

export default function ContactPage() {
  const [mutationPending, setPending] = React.useState(false);

  return (
    <Layout.Contact>
      <Form
        validationRules={contactFormValidations}
        onSubmit={handleSubmit(setPending)}
        disabled={mutationPending}
      >
        <h2 className="mb-4">
          <I18n id="title" d="Contact Us" />
          <span className="text-small mt-2 text-secondary d-block">
            <I18n id="subtitle" d="Feel free to drop us a lie below!" />
          </span>
        </h2>
        <BForm.Group id="">
          <I18n id="namePlaceholder" d="Your name">
            {({ value }) => <Form.Field component={TextInput} placeholder={value} name="name" />}
          </I18n>
        </BForm.Group>
        <BForm.Group id="">
          <I18n id="emailPlaceholder" d="Your email">
            {({ value }) => <Form.Field component={TextInput} placeholder={value} name="email" />}
          </I18n>
        </BForm.Group>
        <BForm.Group id="message">
          <I18n id="textPlaceholder" d="type your message here">
            {({ value }) => (
              <Form.Field component={TextareaInput} placeholder={value} name="message" />
            )}
          </I18n>
        </BForm.Group>

        <Button block type="submit" disabled={mutationPending}>
          <I18n id="submitButton" d="Send" />
        </Button>
      </Form>
    </Layout.Contact>
  );
}

const contactFormValidations = {
  message: { required: true, minLength: 5 },
  email: { required: true, email: true },
  name: { required: true }
};

function handleSubmit(
  setPending: boolean => void
): (data: { [string]: string }, form: { reset: () => void }) => void {
  return function submitContact(data: *, form: { reset: () => void }) {
    setPending(true);
    getCaptchaToken('contact').then(captcha => {
      fetch((process.env.REACT_APP_API_HOST || '') + '/contact', {
        headers: {
          'x-requested-with': 'XMLHttpRequest',
          'content-type': 'application/json'
        },
        body: JSON.stringify({ ...data, captcha }),
        cache: 'no-cache',
        method: 'POST'
      })
        .then(res => res.json())
        .catch(error => ({ error }))
        .then(res => {
          // $FlowFixMe
          const { error, status } = res;
          const code =
            typeof res.code === 'string' && res.code.startsWith('CAPTCHA_') // display same message for 3 errors
              ? 'INVALID_CAPTCHA'
              : (res && res.code) || null;

          const message =
            error || status !== 200 ? (
              <I18n
                id="errMessage$"
                d={{
                  UNKNOWN_ERROR: 'Ooops! Something went wrong.',
                  MESSAGE_REQUIRED: 'Please fill a message text',
                  NAME_REQUIRED: 'Please enter your name',
                  INVALID_EMAIL: 'Invalid email address.',
                  INVALID_CAPTCHA:
                    'Ooops something went wrong with antispam check, please try again.',
                  MESSAGE_LENGTH: 'Please enter longer message'
                }}
              >
                {({ d }) => (code && d[code]) || d.UNKNOWN_ERROR}
              </I18n>
            ) : (
              <I18n id="successMessage" d="Done! Your mesage was sent to our support" />
            );

          toastr({
            type: error || status !== 200 ? 'error' : 'success',
            message
          });

          if (!error) {
            form.reset();
          }

          setPending(false);
        });
    });
  };
}
