// @flow

import * as React from 'react';
import I18n from '_helpers/I18n';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import Link from '../../_components/Link';
import Layout from '../../_components/Layout';
import { Card1, Card2 } from '../_components/PricingCards/PricingCards';

export type SubscriptionPlan = 'MONTHLY' | 'ANNUAL';

// -------------------------------------------------------------------------------------------------

export default function PaymentRoot(): React.Node {
  return (
    <Layout narrow>
      <h1 className="mb-4 mt-4">
        <I18n id="title" d="Upgrade plan" />
      </h1>
      <p className="text-primary">
        <I18n id="subtitl" d="Please subscribe to get access to all premium features" />
      </p>
      <Row className="mb-5 mt-5">
        <Col xs={12} md={6} className="mb-5 mb-md-0">
          <Card1 button={<SubscribeButton plan="MONTHLY" />} />
        </Col>
        <Col xs={12} md={6}>
          <Card2 button={<SubscribeButton plan="ANNUAL" />} />
        </Col>
      </Row>
    </Layout>
  );
}

// -------------------------------------------------------------------------------------------------

function SubscribeButton(props: { plan: SubscriptionPlan }): React.Node {
  return (
    <Link
      to={(process.env.REACT_APP_API_HOST || '') + '/payment/subscribe?type=' + props.plan}
      className="d-inline btn-subscribe"
      style={{ width: 'auto' }}
      component={Button}
    >
      <I18n id="title" d="Subscribe" />
    </Link>
  );
}

// -------------------------------------------------------------------------------------------------
