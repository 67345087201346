// @flow

import idx from 'idx';
import cn from 'classnames';
import * as React from 'react';
import I18n from '_helpers/I18n';
import BForm from 'react-bootstrap/Form';
import Tooltip from 'react-bootstrap/Tooltip';
import Collapse from 'react-bootstrap/Collapse';
import InputGroup from 'react-bootstrap/InputGroup';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import type { OnChangeEvent } from '../../../_components/Form';

import { Button } from 'react-bootstrap';
import Icon from '../../../_components/Icon';
import GoogleAutocompleteInput from '../../../_components/GoogleAutocompleteInput';
import { SliderInput, MapPlacePicker } from '../../../_components/Form';

// -------------------------------------------------------------------------------------------------

export type LocationFilterValue = {
  radius: null | number,
  place: null | Place,
  ignore: boolean,
  open: boolean
};

export type LocationFilterProps = {
  onChange: (OnChangeEvent<LocationFilterValue>) => void,
  value: null | LocationFilterValue,
  step?: number,
  name: string,
  max: number
};

// -------------------------------------------------------------------------------------------------

export default function LocationFilterInput(props: LocationFilterProps): React.Node {
  const { value, onChange, name, max, step } = props;
  const ignored: boolean = !!value && value.ignore;

  const handleChange = React.useCallback(
    (e: OnChangeEvent<*>) => {
      if (ignored && e.name === 'radius') {
        // $FlowFixMe
        onChange({ name, value: { ...value, ignore: false, radius: e.value } });
      } else {
        // $FlowFixMe
        onChange({ name: name + '.' + e.name, value: e.value });
      }
    },
    [name, onChange, value, ignored]
  );

  const ignoreLocation = !!(value && value.ignore);

  const toggleIgnore = React.useCallback(() => {
    onChange({ name, value: { ...value, ignore: !(value && value.ignore) } });
  }, [onChange, value, name]);

  return (
    <Collapse in={value && value.open}>
      <div>
        <BForm.Group controlId="locationFieldset.location">
          <BForm.Label>
            <I18n id="locationLabel" d="Location" />
          </BForm.Label>
          <InputGroup>
            <InputGroup.Prepend>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>
                    {ignoreLocation ? (
                      <I18n id="enableLocationFilter" d="Enable location filter" />
                    ) : (
                      <I18n id="disableLocationFilter" d="Disable location filter" />
                    )}
                  </Tooltip>
                }
              >
                <Button
                  variant={ignoreLocation ? 'secondary' : 'success'}
                  onClick={toggleIgnore}
                  className="d-flex btn-icon"
                >
                  <Icon name={ignoreLocation ? 'close' : 'check'} />
                </Button>
              </OverlayTrigger>
            </InputGroup.Prepend>
            <I18n id="phonePlaceholder" d="Search location">
              {placeholder => (
                <GoogleAutocompleteInput
                  placeholder={placeholder.value}
                  value={value && value.place}
                  disabled={ignoreLocation}
                  onChange={handleChange}
                  name="place"
                />
              )}
            </I18n>
          </InputGroup>
        </BForm.Group>

        <BForm.Group controlId="locationFieldset.radius">
          <BForm.Label>
            <I18n id="radiusLabel" d="Radius" />
          </BForm.Label>
          <SliderInput
            value={(value && value.radius) || 1}
            // $FlowFixMe
            onChange={handleChange}
            knob={RadiusKnob}
            step={step || 1}
            name="radius"
            max={max}
            min={1}
          />
        </BForm.Group>

        <BForm.Group controlId="locationFieldset.map">
          <MapPlacePicker
            radius={(ignoreLocation ? -1 : idx(value, _ => _.radius)) || -1}
            value={value && value.place}
            onChange={handleChange}
            name="place"
          />
        </BForm.Group>
      </div>
    </Collapse>
  );
}

// -------------------------------------------------------------------------------------------------

function RadiusKnob(props: *): React.Node {
  const className = cn('rc-slider-handle', { 'bg-secondary': props.disabled });
  return (
    <span className={className} style={{ left: props.offset + '%' }}>
      {`${props.value > 1 ? props.value - 1 : 1}km`}
    </span>
  );
}
