// @flow

import * as React from 'react';
import I18n from '_helpers/I18n';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import Link from '../../../_components/Link';
import BBCode from '../../../_components/BBCode';

export default function PricingCards(props: {
  buttonLabel: React.ComponentType<{ variant: number }>,
  buttonAction: number => void
}): React.Node {
  const { buttonLabel, buttonAction } = props;
  const Label = buttonLabel;

  const onClick = React.useCallback(
    (variant: number) => {
      return () => buttonAction(variant);
    },
    [buttonAction]
  );

  return (
    <div className="pricing-cards">
      <Card className="d-flex align-items-center p-5">
        <h5 className="text-primary mb-4 text-center">
          <I18n id="card1Title" d="Trial" />
        </h5>
        <h2 className="price text-strong">
          <I18n id="card1Price" d="Free" />
        </h2>
        <p className="text-center mt-4 mb-4">
          <I18n id="card1Text" d="One Opportunity [br /]Limited offer visibility [br /]One Reply">
            {text => <BBCode>{text.value}</BBCode>}
          </I18n>
        </p>
        <Link
          className="text-uppercase"
          variant="outline-primary"
          onClick={onClick(0)}
          component={Button}
          size="lg"
        >
          {<Label variant={0} />}
        </Link>
      </Card>

      <Card1
        button={
          <Link component={Button} onClick={onClick(1)} size="lg" className="text-uppercase">
            {<Label variant={1} />}
          </Link>
        }
      />

      <Card2
        button={
          <Link component={Button} onClick={onClick(1)} size="lg" className="text-uppercase">
            {<Label variant={1} />}
          </Link>
        }
      />
    </div>
  );
}

export function Card1(props: { button: React.Node }): React.Node {
  return (
    <Card className="d-flex align-items-center p-5">
      <h5 className="text-primary mb-4 text-center">
        <I18n id="card2Title" d="Monthly" />
      </h5>
      <h2 className="price text-strong">
        <I18n id="card2Price" d="19€" />
      </h2>
      <p className="text-center mt-4 mb-4">
        <I18n
          id="card2Text"
          d="[b]Unlimited[/b] Opportunities [br /]See all company details [br /][b]Unlimited[/b] Replies"
        >
          {text => <BBCode>{text.value}</BBCode>}
        </I18n>
      </p>
      {props.button}
    </Card>
  );
}

export function Card2(props: { button: React.Node }): React.Node {
  return (
    <Card className="d-flex align-items-center p-5">
      <h5 className="text-primary mb-4 text-center">
        <I18n id="card3Title" d="Annual" />
      </h5>
      <h2 className="price text-strong">
        <I18n id="card3Price" d="199€ [sup]Save 12%[/sup]">
          {price => <BBCode>{price.value}</BBCode>}
        </I18n>
      </h2>
      <p className="text-center mt-4 mb-4">
        <I18n
          id="card3Text"
          d="[b]Unlimited[/b] Opportunities [br /]See all company details [br /][b]Unlimited[/b] Replies"
        >
          {text => <BBCode>{text.value}</BBCode>}
        </I18n>
      </p>
      {props.button}
    </Card>
  );
}
