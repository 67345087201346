// @flow

import * as React from 'react';
import MessageFormat from 'intl-messageformat';

// -------------------------------------------------------------------------------------------------

let source = {};
let activeLocale = '';

const cache = {};

// -------------------------------------------------------------------------------------------------

const languages = {
  sk: () => import('../../_assets/I18n/sk.json').then(mod => mod.default),
  cz: () => import('../../_assets/I18n/cz.json').then(mod => mod.default),
  en: () => import('../../_assets/I18n/en.json').then(mod => mod.default)
};

// -------------------------------------------------------------------------------------------------

export type I18nProps = {
  children?: ({ d: { [string]: string }, value: string | null }) => React.Node,
  component?: React.ComponentType<*>,
  d: string | { [string]: string },
  v?: string | number,
  id: string
};

// -------------------------------------------------------------------------------------------------

export default function I18n(props: I18nProps): React.Node {
  const { id, d, v, children, component, ...options } = props;

  if (props.id.endsWith('$')) {
    const keyMap = Object.keys(source)
      .filter(key => key.startsWith(id))
      .reduce(
        (obj, key) => ({ ...obj, [key.replace(id + '.', '')]: source[key] }),
        d && typeof d === 'object' ? d : {}
      );

    let value: string = v ? keyMap[typeof v === 'number' ? v.toString() : v] : '';
    value = value ? format(value, activeLocale, options) : value;

    if (component) {
      const C = component;
      return <C {...options} d={keyMap} value={value} />;
    }
    return children ? children({ ...options, d: keyMap, value }) : value;
  }

  let value = source[id] || d;
  value = value ? format(value, activeLocale, options) : value;

  if (component) {
    const C = component;
    return <C {...options} d={d} value={value} />;
  }

  return children ? children({ ...options, d: typeof d === 'object' ? d : {}, value }) : value;
}

// -------------------------------------------------------------------------------------------------

export function setLocaleSource(locale: string): Promise<string> {
  const target = languages[locale] ? locale : 'en';

  if (cache[target]) {
    source = cache[target];
    return Promise.resolve(target);
  }

  return languages[target]().then(res => {
    source = res;
    activeLocale = locale;
    return activeLocale;
  });
}

// -------------------------------------------------------------------------------------------------

export function getActiveLocale() {
  return activeLocale + '';
}

// -------------------------------------------------------------------------------------------------

export function format(template: string, locale: string, props: Object): string {
  try {
    const msg = new MessageFormat(template, locale);
    return msg.format(props);
  } catch (e) {
    // eslint-disable-next-line
    console.error(new Error(`I18n: Pluralization failed for "${template}"`));
    return template;
  }
}
