// @flow

import * as React from 'react';
import { hot } from 'react-hot-loader/root';
import Router from 'react-router-dom/Router';

import Menu from './_components/Menu';
import routes from './_pages/_helpers/routes';
import me from './_helpers/Api/Auth/actions/me';
import { routerHistory } from './_helpers/router';
import { ErrorBoundary } from './_helpers/Logger';
import IdentityProvider from './_helpers/Identity';
import { Provider as I18nProvider } from '_helpers/I18n';
import { renderRoutes, ScrollToTop } from './_helpers/router';
import LoadingContent from './_pages/_components/LoadingContent';

// -------------------------------------------------------------------------------------------------

class App extends React.PureComponent<*> {
  render(): React.Node {
    return (
      <ErrorBoundary>
        <IdentityProvider confirmIdentity={me}>
          <I18nProvider>
            {loading =>
              loading ? (
                <LoadingContent />
              ) : (
                <>
                  <Menu />
                  <Router history={routerHistory}>
                    <ScrollToTop>{renderRoutes(routes)}</ScrollToTop>
                  </Router>
                </>
              )
            }
          </I18nProvider>
        </IdentityProvider>
      </ErrorBoundary>
    );
  }
}

export default hot(App);
