// @flow

import idx from 'idx';
import I18n from '_helpers/I18n';
import * as React from 'react';
import ReactDOMServer from 'react-dom/server';

import type { OnChangeEvent } from '../../_components/Form';

import { Query } from '../../_helpers/Api';
import { Typeahead } from '../../_components/Form';

// -------------------------------------------------------------------------------------------------

export type SearchValue = { value: ID, type: 'KEYWORD' | 'CATEGORY', label: string };

export type CategoryKeywordAutocompleteProps = {
  onChange: (OnChangeEvent<SearchValue>) => void,
  value: null | SearchValue,
  placeholder?: string,
  name: string,
  id: string
};

// -------------------------------------------------------------------------------------------------

export default function CategoryKeywordAutocomplete(
  props: CategoryKeywordAutocompleteProps
): React.Node {
  const [search, setSearch] = React.useState('');
  const { value, onChange, id, ...rest } = props;

  return (
    <Query name="searchCategoryOrKeyword" variables={{ search }}>
      {({ loading, data }) => {
        return (
          <Typeahead
            {...rest}
            placeholder={ReactDOMServer.renderToString(
              <I18n id="placeholder" d="Search Category or Keyword..." />
            )}
            value={value ? [value] : []}
            // $FlowFixMe
            onChange={changeHandler(onChange)}
            // $FlowFixMe
            options={decorateOptions(data)}
            className="control-search"
            onSearch={setSearch}
            loading={loading}
            id={id}
          />
        );
      }}
    </Query>
  );
}

// -------------------------------------------------------------------------------------------------

function changeHandler(
  onChange: (OnChangeEvent<SearchValue>) => void
): (OnChangeEvent<Array<SearchValue>>) => void {
  return function handleOnChange(event: OnChangeEvent<Array<SearchValue>>): void {
    const { name, value } = event;
    const evt = { name, value: value ? value[0] : null };
    onChange && onChange(evt);
  };
}

function decorateOptions(data: *): Array<SearchValue> {
  return idx(data, _ => _.search.results) || [];
}
