/**
 * @flow
 * @relayHash 0012889fda7247c238ab101e7ddc1e0e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type MessagingProvider = "ICQ" | "SIGNAL" | "SKYPE" | "TELEGRAM" | "VIBER" | "WHATSAPP" | "%future added value";
export type Salutation = "MR" | "MS" | "%future added value";
export type UserDetailsInput = {|
  salutation?: ?Salutation,
  firstName: string,
  lastName: string,
  phone?: ?string,
  email: string,
  contactEmail?: ?string,
  messaging: $ReadOnlyArray<MessageIdentityInput>,
  linkedin?: ?string,
  password?: ?string,
|};
export type MessageIdentityInput = {|
  type: MessagingProvider,
  value: string,
|};
export type ProfilePageSaveDetailsMutationVariables = {|
  details: UserDetailsInput
|};
export type ProfilePageSaveDetailsMutationResponse = {|
  +myDetails: {|
    +id: string,
    +salutation: ?Salutation,
    +firstName: string,
    +lastName: string,
    +phone: ?string,
    +email: string,
    +contactEmail: ?string,
    +messaging: $ReadOnlyArray<{|
      +type: MessagingProvider,
      +value: string,
    |}>,
    +linkedin: ?string,
    +company: {|
      +id: string,
      +populated: boolean,
    |},
  |}
|};
export type ProfilePageSaveDetailsMutation = {|
  variables: ProfilePageSaveDetailsMutationVariables,
  response: ProfilePageSaveDetailsMutationResponse,
|};
*/

/*
mutation ProfilePageSaveDetailsMutation(
  $details: UserDetailsInput!
) {
  myDetails(details: $details) {
    id
    salutation
    firstName
    lastName
    phone
    email
    contactEmail
    messaging {
      type
      value
      id
    }
    linkedin
    company {
      id
      populated
    }
  }
}
*/

const node /*: ConcreteRequest*/ = (function() {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'details',
        type: 'UserDetailsInput!',
        defaultValue: null
      }
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'details',
        variableName: 'details'
      }
    ],
    v2 = {
      kind: 'ScalarField',
      alias: null,
      name: 'id',
      args: null,
      storageKey: null
    },
    v3 = {
      kind: 'ScalarField',
      alias: null,
      name: 'salutation',
      args: null,
      storageKey: null
    },
    v4 = {
      kind: 'ScalarField',
      alias: null,
      name: 'firstName',
      args: null,
      storageKey: null
    },
    v5 = {
      kind: 'ScalarField',
      alias: null,
      name: 'lastName',
      args: null,
      storageKey: null
    },
    v6 = {
      kind: 'ScalarField',
      alias: null,
      name: 'phone',
      args: null,
      storageKey: null
    },
    v7 = {
      kind: 'ScalarField',
      alias: null,
      name: 'email',
      args: null,
      storageKey: null
    },
    v8 = {
      kind: 'ScalarField',
      alias: null,
      name: 'contactEmail',
      args: null,
      storageKey: null
    },
    v9 = {
      kind: 'ScalarField',
      alias: null,
      name: 'type',
      args: null,
      storageKey: null
    },
    v10 = {
      kind: 'ScalarField',
      alias: null,
      name: 'value',
      args: null,
      storageKey: null
    },
    v11 = {
      kind: 'ScalarField',
      alias: null,
      name: 'linkedin',
      args: null,
      storageKey: null
    },
    v12 = {
      kind: 'LinkedField',
      alias: null,
      name: 'company',
      storageKey: null,
      args: null,
      concreteType: 'Company',
      plural: false,
      selections: [
        (v2 /*: any*/),
        {
          kind: 'ScalarField',
          alias: null,
          name: 'populated',
          args: null,
          storageKey: null
        }
      ]
    };
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'ProfilePageSaveDetailsMutation',
      type: 'Mutation',
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'myDetails',
          storageKey: null,
          args: (v1 /*: any*/),
          concreteType: 'User',
          plural: false,
          selections: [
            (v2 /*: any*/),
            (v3 /*: any*/),
            (v4 /*: any*/),
            (v5 /*: any*/),
            (v6 /*: any*/),
            (v7 /*: any*/),
            (v8 /*: any*/),
            {
              kind: 'LinkedField',
              alias: null,
              name: 'messaging',
              storageKey: null,
              args: null,
              concreteType: 'MessageIdentity',
              plural: true,
              selections: [(v9 /*: any*/), (v10 /*: any*/)]
            },
            (v11 /*: any*/),
            (v12 /*: any*/)
          ]
        }
      ]
    },
    operation: {
      kind: 'Operation',
      name: 'ProfilePageSaveDetailsMutation',
      argumentDefinitions: (v0 /*: any*/),
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'myDetails',
          storageKey: null,
          args: (v1 /*: any*/),
          concreteType: 'User',
          plural: false,
          selections: [
            (v2 /*: any*/),
            (v3 /*: any*/),
            (v4 /*: any*/),
            (v5 /*: any*/),
            (v6 /*: any*/),
            (v7 /*: any*/),
            (v8 /*: any*/),
            {
              kind: 'LinkedField',
              alias: null,
              name: 'messaging',
              storageKey: null,
              args: null,
              concreteType: 'MessageIdentity',
              plural: true,
              selections: [(v9 /*: any*/), (v10 /*: any*/), (v2 /*: any*/)]
            },
            (v11 /*: any*/),
            (v12 /*: any*/)
          ]
        }
      ]
    },
    params: {
      operationKind: 'mutation',
      name: 'ProfilePageSaveDetailsMutation',
      id: null,
      text:
        'mutation ProfilePageSaveDetailsMutation(\n  $details: UserDetailsInput!\n) {\n  myDetails(details: $details) {\n    id\n    salutation\n    firstName\n    lastName\n    phone\n    email\n    contactEmail\n    messaging {\n      type\n      value\n      id\n    }\n    linkedin\n    company {\n      id\n      populated\n    }\n  }\n}\n',
      metadata: {}
    }
  };
})();
// prettier-ignore
(node/*: any*/).hash = '1487790b815ad246079e392420bf33b9';
module.exports = node;
