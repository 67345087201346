// @flow

import idx from 'idx';
import I18n from '_helpers/I18n';
import * as React from 'react';
import { graphql } from 'react-relay';

import type { UserFormData } from './_components/UserForm';
import type { MessagingInputValue } from '../../_components/Form/_components/Messaging';
import type { UserDetailsInput } from './__generated__/ProfilePageSaveDetailsMutation.graphql';

import toastr from '../../_helpers/toastr';
import UserForm from './_components/UserForm';
import Layout from '../../_components/Layout';
import { getQueryParam, routerHistory } from '../../_helpers/router';
import LoadingContent from '../_components/LoadingContent';
import { Query, commitMutationPromise } from '../../_helpers/Api';

// -------------------------------------------------------------------------------------------------

export default function AccountPage(props: *): React.Node {
  const [mutationPending, setPending] = React.useState(false);
  return (
    <Layout.Common narrow>
      <Query name="me">
        {({ loading, error, data }) => {
          if (loading) {
            return <LoadingContent />;
          }

          return (
            <>
              <h1 className="mb-4 mt-4">
                <I18n id="title" d="My account" />
              </h1>
              <UserForm
                onSubmit={mutationPending ? devNull : handleSubmit(setPending)}
                defaultValue={decorateMeToUserForm(idx(data, _ => _.me) || null)}
                disabled={mutationPending}
              />
            </>
          );
        }}
      </Query>
    </Layout.Common>
  );
}

// -------------------------------------------------------------------------------------------------

function handleSubmit(setPending: boolean => void): (null | UserFormData) => void {
  return function(data: null | UserFormData): void {
    if (!data || typeof data !== 'object') {
      return;
    }

    const details = decorateMutationInput(data);

    const nextStep = getQueryParam('next-step');

    setPending(true);
    commitMutationPromise({
      mutation,
      variables: { details }
    })
      .then(response => ({ response, error: undefined }))
      .catch(error => ({ error }))
      .then(({ error }) => {
        setPending(false);
        if (nextStep) {
          routerHistory.push(nextStep);
        }
        toastr({
          type: error ? 'error' : 'success',
          message: error ? (
            <I18n id="profileError" d="Ooops. Your profile details was not saved" />
          ) : (
            <I18n id="profileSaved" d="Profile has been saved successfully" />
          )
        });
      });
  };
}

function devNull(): void {}

// -------------------------------------------------------------------------------------------------

function decorateMeToUserForm(data: { [string]: * } | null): UserFormData {
  let messaging = null;

  if (data && data.messaging) {
    messaging = data.messaging.reduce(
      (map: Array<MessagingInputValue>, it: MessagingInputValue, key: number) => {
        map[key] = it;
        return map;
      },
      {}
    );
  }

  // $FlowFixMe
  return {
    ...(data || {}),
    messaging
  };
}

// -------------------------------------------------------------------------------------------------

const mutation = graphql`
  mutation ProfilePageSaveDetailsMutation($details: UserDetailsInput!) {
    myDetails(details: $details) {
      id
      salutation
      firstName
      lastName
      phone
      email
      contactEmail
      messaging {
        type
        value
      }
      linkedin
      company {
        id
        populated
      }
    }
  }
`;

// -------------------------------------------------------------------------------------------------

function decorateMutationInput(data: { [string]: any }): UserDetailsInput {
  const reduceMessaging = (list, index) => {
    // TODO: refactor op.set() to be able set array index, at this moment, op.set in form onchage action, change array to object if op.set(data,"param.0.nested", ...) is called
    if (data && data.messaging && data.messaging[index].value && data.messaging[index].type) {
      list.push(data.messaging[index]);
    }
    return list;
  };

  return {
    messaging: data.messaging ? Object.keys(data.messaging).reduce(reduceMessaging, []) : [],
    contactEmail: data.contactEmail || null,
    salutation: data.salutation || null,
    firstName: data.firstName || null,
    lastName: data.lastName || null,
    linkedin: data.linkedin || null,
    password: data.password || null,
    phone: data.phone || null,
    email: data.email || ''
  };
}
