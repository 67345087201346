// @flow

import { getEndpoint } from './';

export type ForgottenActionVariables = {|
  email: string
|};

export default function forgotten(variables: ForgottenActionVariables): Promise<*> {
  const host = getEndpoint();
  return fetch(host + '/auth/forgotten', {
    method: 'POST',
    headers: {
      'x-requested-with': 'XMLHttpRequest',
      'content-type': 'application/json'
    },
    body: JSON.stringify(variables),
    cache: 'no-cache'
  })
    .then(response => response.json())
    .catch(error => ({
      ok: false,
      error
    }));
}
