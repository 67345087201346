// @flow

export default function googlePlaceParser(place: GooglePlace): Place {
  const components = (place && place.address_components) || [];

  // TODO: rewrite Reduce, e.g. 1 loop and fill all required vars

  // $FlowFixMe
  const premise = components.reduce(find('premise'), null);
  // $FlowFixMe
  const number = components.reduce(find('street_number'), null);
  // $FlowFixMe
  const street = components.reduce(find('route'), '');
  // $FlowFixMe
  const stateCode = components.reduce(find('administrative_area_level_1', true), '');

  let streetNumber = street;
  if (premise) {
    streetNumber += ' ' + premise;
  }
  if (number) {
    streetNumber += (premise ? '/' : ' ') + number;
  }

  // $FlowFixMe
  const sublocality = components.reduce(find('sublocality'), null);
  const locality =
    // $FlowFixMe
    components.reduce(find('postal_town'), null) ||
    // $FlowFixMe
    components.reduce(find('locality'), null) ||
    // $FlowFixMe
    components.reduce(find('administrative_area_level_2'), null);

  let city = '' + (locality || '');

  if (sublocality && sublocality !== locality && city.indexOf(sublocality) === -1) {
    city += (locality ? ' - ' : '') + sublocality;
  }

  // $FlowFixMe
  const countryCode = components.reduce(find('country', true), null);

  const result: Place = {
    street: streetNumber,
    city,
    // $FlowFixMe
    postalCode: components.reduce(find('postal_code'), null),
    // $FlowFixMe
    country: components.reduce(find('country'), null),
    countryCode: countryCode,
    state: '',
    stateCode: countryCode === 'US' && stateCode.length === 2 ? stateCode : '',
    coords: {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng()
    }
  };

  return result;
}

const find = (target: string, short?: boolean) => (
  prev: null | string,
  item: { types: Array<string>, long_name?: string, short_name?: string }
): null | string => {
  return (
    prev ||
    // $FlowFixMe
    (item.types.some(type => type === target) ? (short ? item.short_name : item.long_name) : null)
  );
};
