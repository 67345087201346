/**
 * @flow
 * @relayHash 387c2555a88afc69c694f47f3efa9f84
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CategoryKeywordResponseType = "CATEGORY" | "KEYWORD" | "%future added value";
export type searchCategoryOrKeywordQueryVariables = {|
  search?: ?string
|};
export type searchCategoryOrKeywordQueryResponse = {|
  +search: {|
    +results: $ReadOnlyArray<{|
      +label: string,
      +value: ?string,
      +type: CategoryKeywordResponseType,
    |}>
  |}
|};
export type searchCategoryOrKeywordQuery = {|
  variables: searchCategoryOrKeywordQueryVariables,
  response: searchCategoryOrKeywordQueryResponse,
|};
*/

/*
query searchCategoryOrKeywordQuery(
  $search: String
) {
  search {
    results: categoryOrKeyword(search: $search, first: 300) {
      label: name
      value: code
      type
    }
  }
}
*/

const node /*: ConcreteRequest*/ = (function() {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'search',
        type: 'String',
        defaultValue: null
      }
    ],
    v1 = [
      {
        kind: 'LinkedField',
        alias: null,
        name: 'search',
        storageKey: null,
        args: null,
        concreteType: 'SearchQueries',
        plural: false,
        selections: [
          {
            kind: 'LinkedField',
            alias: 'results',
            name: 'categoryOrKeyword',
            storageKey: null,
            args: [
              {
                kind: 'Literal',
                name: 'first',
                value: 300
              },
              {
                kind: 'Variable',
                name: 'search',
                variableName: 'search'
              }
            ],
            concreteType: 'CategoryKeywordResponse',
            plural: true,
            selections: [
              {
                kind: 'ScalarField',
                alias: 'label',
                name: 'name',
                args: null,
                storageKey: null
              },
              {
                kind: 'ScalarField',
                alias: 'value',
                name: 'code',
                args: null,
                storageKey: null
              },
              {
                kind: 'ScalarField',
                alias: null,
                name: 'type',
                args: null,
                storageKey: null
              }
            ]
          }
        ]
      }
    ];
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'searchCategoryOrKeywordQuery',
      type: 'Query',
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: (v1 /*: any*/)
    },
    operation: {
      kind: 'Operation',
      name: 'searchCategoryOrKeywordQuery',
      argumentDefinitions: (v0 /*: any*/),
      selections: (v1 /*: any*/)
    },
    params: {
      operationKind: 'query',
      name: 'searchCategoryOrKeywordQuery',
      id: null,
      text:
        'query searchCategoryOrKeywordQuery(\n  $search: String\n) {\n  search {\n    results: categoryOrKeyword(search: $search, first: 300) {\n      label: name\n      value: code\n      type\n    }\n  }\n}\n',
      metadata: {}
    }
  };
})();
// prettier-ignore
(node/*: any*/).hash = '8de4676ebf3e3a60a8b9b5715889a1f7';
module.exports = node;
