// @flow

import * as React from 'react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import type { OnChangeEvent } from '../types';

export default function DateInput(props: {
  onChange: (OnChangeEvent<Date>) => void,
  value: null | Date,
  name: string
}): React.Node {
  const { onChange, name, value } = props;

  const handler = React.useCallback(
    (value: null | Date) => {
      const event = { name, value: value || null };
      onChange(event);
    },
    [name, onChange]
  );

  return (
    <DatePicker
      className="form-control"
      dateFormat="dd.MM.YYYY" // TODO: Make configurable
      onSelect={handler}
      selected={value}
      name={name}
      {...props}
    />
  );
}
