// @flow

import idx from 'idx';
import * as React from 'react';
import I18n from '_helpers/I18n';
import { graphql } from 'react-relay';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Redirect from 'react-router-dom/Redirect';

import type { DealFormData } from './_components/DealForm';

import Link from '../../_components/Link';
import toastr from '../../_helpers/toastr';
import Query from '../../_helpers/Api/Query';
import Layout from '../../_components/Layout';
import DealForm from './_components/DealForm';
import { routerHistory } from '../../_helpers/router';
import { commitMutationPromise } from '../../_helpers/Api';
import LoadingContent from '../_components/LoadingContent';
import { decorateDealInput } from './_helpers/decorateDealInput';
import { Context as IdentityContext } from '../../_helpers/Identity';

// -------------------------------------------------------------------------------------------------

export default function NewDealPage(props: *): React.Node {
  const [pendingMutation, setPending] = React.useState<boolean>(false);
  const { identity } = React.useContext(IdentityContext);

  if (!identity) {
    return <Redirect to="/error/401" />;
  }

  return (
    <Query name="me">
      {({ loading, error, data }) => {
        if (loading) {
          return <LoadingContent />;
        }

        // $FlowFixMe
        if (!idx(data, _ => _.me.company.paying) && idx(data, _ => _.me.company.deals.total) > 0) {
          return <Redirect to="/subscribe" />;
        }

        const populated = idx(data, _ => _.me.company.populated);

        return (
          <Layout narrow>
            <h1 className="mb-4 mt-4">
              <I18n id="title" d="Create new Opportunity" />
            </h1>

            {!populated && (
              <Alert variant="danger" className="d-flex">
                <span>
                  <I18n id="populateCompanyAlert" d="Please enter your company details first" />
                </span>

                <Link to="/account/company" className="ml-auto">
                  <I18n id="populateCompanyAlertButton" d="Update company" />
                </Link>
              </Alert>
            )}

            <DealForm
              onSubmit={getSubmitHandler(pendingMutation, setPending)}
              disabled={!populated || pendingMutation}
            >
              <Button
                className="mr-0 mr-md-3 d-block d-md-inline mb-3 mb-md-0"
                disabled={!populated || pendingMutation}
                type="submit"
              >
                <I18n id="submitButton" d="Create new Opportunity" />
              </Button>
              <Link component={Button} variant="outline-dark" to="/account/my-deals">
                <I18n id="cancelButton" d="Cancel" />
              </Link>
            </DealForm>
          </Layout>
        );
      }}
    </Query>
  );
}

// -------------------------------------------------------------------------------------------------

function getSubmitHandler(
  pendingMutation: boolean,
  setPending: boolean => void
): (null | DealFormData) => void {
  return function handleSubmit(data: null | DealFormData): void {
    if (pendingMutation || !data) {
      return;
    }

    setPending(true);
    commitMutationPromise({
      variables: { data: decorateDealInput(data) },
      mutation: newDealMutation
    })
      .then(response => ({ response, error: undefined }))
      .catch(error => ({ error }))
      .then(({ error }) => {
        setPending(false);
        toastr({
          type: error ? 'error' : 'success',
          message: error ? (
            <I18n id="newDealFailed" d="New Opportunity cannot be saved" />
          ) : (
            <I18n
              d="New Opportunity '{summary}' has been added"
              id="newDealCreated"
              // $FlowFixMe
              summary={data.summary}
            />
          )
        });

        !error && routerHistory.push('/account/my-deals');
      });
  };
}

// -------------------------------------------------------------------------------------------------

// TODO: use fragment maybe ?
const newDealMutation = graphql`
  mutation NewDealPageMutation($data: DealInput!) {
    deal: createDeal(data: $data) {
      id
      status
      cooperation
      type
      summary
      category {
        id
        name
      }
      expires
      description
      keywords
      partnerRequirements
      attachments {
        id
        name
        url
      }
      video
      created
      modified
      company {
        id
      }
    }
  }
`;
