// @flow
import idx from 'idx';

import me from './me';
import { getEndpoint } from './';

export type LoginActionVariables = {|
  rememberMe: boolean,
  password: string,
  email: string
|};

export default function login(variables: LoginActionVariables): Promise<*> {
  return fetch(getEndpoint() + '/auth/login', {
    method: 'POST',
    headers: {
      'x-requested-with': 'XMLHttpRequest',
      'content-type': 'application/json'
    },
    body: JSON.stringify(variables),
    credentials: 'include',
    cache: 'no-cache'
  })
    .then(res => res.json())
    .then(response => {
      if (response.ok) {
        return me().then(res => {
          const user = idx(res, _ => _.data);
          if (user && typeof user.id === 'number') {
            user.id = user.id + '';
          }
          if (user && user.company && typeof user.company.id === 'number') {
            user.company.id = user.company.id + '';
          }

          return {
            status: 200,
            data: user,
            ok: true
          };
        });
      }
      return response;
    })
    .catch(error => ({
      ok: false,
      error
    }));
}
