// @flow

import idx from 'idx';
import I18n from '_helpers/I18n';
import * as React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { graphql } from 'react-relay';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import type { DealsFilterType } from './_components/SearchResults';

import Icon from '../_components/Icon';
import Layout from '../_components/Layout';
import { DealTypePicker } from './Account/MyDealsPage'; // TODO export to extra file
import Searchresults from './_components/SearchResults';
import Form, { FormContext } from '../_components/Form';
import SearchDealsFilter from './_components/SearchDealsFilter';
import LocationFilterInput from './_components/LocationFilterInput';
import MyLocationFakeInput from './_components/MyLocationFakeInput';
import { parseFilter, getFilterUrlAction } from './_helpers/dealUrlFilter';

// -------------------------------------------------------------------------------------------------

const MAX_RADIUS = 1001;

const defaultFilter: DealsFilterType = {
  cooperation: [],
  category: null,
  type: null,
  keywords: [],
  status: null,
  location: {
    place: null,
    radius: 100,
    open: false,
    ignore: true
  }
};

// -------------------------------------------------------------------------------------------------

const updateFilterUrl = getFilterUrlAction('/deal');

export default class SearchDealsPage extends React.PureComponent<*> {
  initialFilter = parseFilter('');

  constructor(props: *) {
    super(props);
    this.initialFilter = parseFilter('?' + (window.location.search || ''));
  }

  render(): React.Node {
    return (
      <Layout.Common>
        <Form defaultValue={this.initialFilter} didChange={updateFilterUrl}>
          <Form.Field component={MyLocationFakeInput} name="location.place" />
          <Row className="deal-filter-header mb-4 mt-4">
            <Col className="d-md-flex align-items-center justify-content-between">
              <h1>
                <I18n id="title" d="Open Opportunities" />
              </h1>
              <div className="float-sm-right d-flex flex-column flex-sm-row">
                <Form.Field
                  className="mr-0 mb-2 mr-sm-2 mb-sm-0"
                  component={DealTypePicker}
                  variant="secondary"
                  name="type"
                />
                <Form.Field name="location" component={OpenMapButton} />
              </div>
            </Col>
          </Row>

          <SearchDealsFilter />

          <Form.Field component={LocationFilterInput} max={MAX_RADIUS} step={50} name="location" />

          <Searchresults
            filterDecorator={decorateFilter}
            contexRef={FormContext} // Connected to form context
            noResults={NoResults}
            query={searchQuery}
            mine={false}
          />
        </Form>
      </Layout.Common>
    );
  }
}

// -------------------------------------------------------------------------------------------------

function NoResults() {
  return (
    <I18n
      d="No opportunities were found. Please change your search criteria and try again"
      id="noResultsPlaceholder"
    />
  );
}

// -------------------------------------------------------------------------------------------------

// Decorate Form schema to query params
function decorateFilter(data: *): DealsFilterType {
  const categoryOrKeyword = (data && data.categoryOrKeyword) || {};
  const location = data.location || {};

  const filter = {
    ...data,
    category: categoryOrKeyword.type === 'CATEGORY' ? parseInt(categoryOrKeyword.value, 10) : null,
    keywords: categoryOrKeyword.type === 'KEYWORD' ? [categoryOrKeyword.label] : [],
    location: idx(data, _ => _.location.ignore) === true ? {} : location
  };

  return filter;
}

export { defaultFilter };

// -------------------------------------------------------------------------------------------------

function OpenMapButton(props: *): React.Node {
  const { onChange, value, name } = props;

  const toggleOpen = React.useCallback(() => {
    onChange({ name, value: { ...(value || {}), open: !(value && value.open) } });
  }, [name, value, onChange]);

  const open = value && value.open;
  const ignoreLocation = !!(value && value.ignore);

  const c = ignoreLocation ? 'gray' : 'success';

  return (
    <ButtonGroup>
      <Button
        variant={open ? c : 'outline-' + c}
        className="d-flex filter-button"
        onClick={toggleOpen}
        size="sm"
      >
        <Icon name="location" className="mr-1 sole-icon" />
        {open ? (
          <I18n id="closeMabButton" d="Close map" />
        ) : (
          <I18n id="mapFilterButton" d="Open map" />
        )}
        <Icon name={open ? 'chevron-up' : 'chevron-down'} className="ml-3 sole-icon" />
      </Button>
    </ButtonGroup>
  );
}

// -------------------------------------------------------------------------------------------------

const searchQuery = graphql`
  query SearchDealsPageQuery(
    $cooperation: [DealCooperation!]
    $location: LocationInput
    $keywords: [Keyword!]
    $status: DealStatus
    $type: DealType
    $category: ID
    $first: Int!
    $offset: Int
  ) {
    search {
      results: dealsConnection(
        cooperation: $cooperation
        location: $location
        category: $category
        keywords: $keywords
        offset: $offset
        status: $status
        first: $first
        type: $type
        mine: false
      )
        @connection(
          key: "SearchDeals_results"
          filters: ["cooperation", "location", "categories", "keywords", "status", "type"]
        ) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            summary
            type
            cooperation
            status
            category {
              id
              name
            }
            keywords
            created
            description
            unseenContracts: contracts(seen: false) {
              total
            }
          }
        }
        total
      }
    }
  }
`;
