// @flow

import idx from 'idx';
import * as React from 'react';

import DealList from '../DealList';
import { FormContext } from '../../../_components/Form';
import Pagination from '../../../_components/Pagination';
// -------------------------------------------------------------------------------------------------

export default function DealPaginationComponent(props: {
  noResults?: React.ElementType,
  editMode?: boolean,
  // eslint-disable-next-line
  results: ?{
    total: number,
    edges: Array<*>,
    pageInfo: {
      hasPreviousPage: boolean,
      hasNextPage: boolean,
      startCursor: null | number,
      endCursor: null | number
    }
  },
  first: number
}): React.Node {
  const connection = idx(props, _ => _.results) || {};
  const total = connection.total || 0;
  const items: Array<*> = connection.edges || [];
  const Fallback = props.noResults;
  const first = props.first;

  const { data, onChange } = React.useContext(FormContext);

  const setPage = React.useCallback(
    (page: number) => {
      onChange({ name: 'page', value: page });
    },
    [onChange]
  );

  let pages = (total / first) | 0;
  pages = pages < total / first ? pages + 1 : pages;
  const page = Math.max(data.page || 0, 1);

  return (
    <>
      {items && items.length > 0 ? (
        <DealList editMode={props.editMode} items={items} />
      ) : (
        // $FlowFixMe
        Fallback && <Fallback />
      )}

      <Pagination onRequestPage={setPage} page={page} pages={pages} />
    </>
  );
}
