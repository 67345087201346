// @flow

import * as Sentry from '@sentry/browser';

let initialised = false;
const LOCAL = process.env.NODE_ENV !== 'production';
const CONSOLE = LOCAL || process.env.REACT_APP_STAGE !== 'production';

export type LogLevel = 'debug' | 'info' | 'warning' | 'error' | 'fatal';

// const CONSOLE_COLOR = {
//   info: 'lightblue',
//   warning: 'orange',
//   fatal: 'purple',
//   debug: 'gray',
//   error: 'red'
// };

const LEVEL_VEIGHT = {
  debug: -1,
  info: 0,
  warning: 1,
  fatal: 2,
  error: 3
};

let lastUser = null;

const SENTRY_LOG_LEVEL =
  LEVEL_VEIGHT[process.env.REACT_APP_SENTRY_LEVEL || 'warning'] || LEVEL_VEIGHT.warning;

export default class Logger {
  static notify(error: any, level?: LogLevel, extra?: any, groupBy?: string | Array<string>): void {
    if (!level && error instanceof Error) {
      level = 'error'; // eslint-disable-line
    }
    const numLevel =
      LEVEL_VEIGHT[process.env.REACT_APP_SENTRY_LEVEL || 'info'] || LEVEL_VEIGHT.info;

    if (numLevel >= SENTRY_LOG_LEVEL) {
      Sentry.withScope(scope => {
        if (extra) {
          scope.setExtra('extra', extra);
        }
        if (groupBy) {
          scope.setFingerprint(typeof groupBy === 'string' ? [groupBy] : groupBy);
        }
        if (level) {
          scope.setLevel(level);
        }
        Sentry.captureException(error);
      });
    }

    if (CONSOLE) {
      // eslint-disable-next-line
      console.log(
        // '%cApp:',
        // `background: ${
        //   level ? CONSOLE_COLOR[level] : CONSOLE_COLOR.info
        // }; color: white; border-radius: 3px; padding: 2px 5px;`,
        error.reason || error.message || error
      );
    }
  }

  static debug(error: any, extra: any, group: any) {
    Logger.notify(error, 'debug', extra, group);
  }

  static error(error: any, extra: any, group: any) {
    Logger.notify(error, 'error', extra, group);
  }

  static warning(error: any, extra: any, group: any) {
    Logger.notify(error, 'warning', extra, group);
  }

  static info(error: any, extra: any, group: any) {
    Logger.notify(error, 'info', extra, group);
  }

  static init(): void {
    const SENTRY_ACCOUNT = process.env.REACT_APP_SENTRY_DSN;

    if (!initialised && SENTRY_ACCOUNT && !LOCAL) {
      initialised = true;

      Sentry.init({
        enviroment: process.env.REACT_APP_STAGE,
        release: window.version,
        attachStacktrace: true,
        dsn: SENTRY_ACCOUNT
      });

      Sentry.configureScope(scope => {
        scope.setUser(lastUser);
      });
    }
  }

  static setIdentity(id: string, device: string): void {
    lastUser = { id, device };

    if (!initialised) {
      return;
    }

    Sentry.configureScope(scope => {
      scope.setUser(lastUser);
    });
  }
}
