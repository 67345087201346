// @flow

import I18n from '_helpers/I18n';
import * as React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BForm from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import type { OnChangeEvent } from '../../';

import Icon from '../../../Icon';
import MapPlacePicker from '../MapPlacePicker';
import GoogleAutocompleteInput, { placeToString } from '../../../GoogleAutocompleteInput';
import Form, { TextInput, CountryInput, USStateInput, FormConsumer, FormContext } from '../..';

// -------------------------------------------------------------------------------------------------

export type LocationModalProps = {
  onRequestConfirm: (null | Place) => void,
  onRequestClose: () => void,
  defaultValue: null | Place,
  title?: React.Node,
  open: boolean
};

const placeValidationRules = {
  street: { required: true },
  city: { required: true },
  countryCode: { required: true },
  stateCode: { required: (_, d) => d.countryCode === 'US' },
  postalCode: { required: true, zip: true }
};

// -------------------------------------------------------------------------------------------------

export default function LocationModal(props: LocationModalProps): React.Node {
  const { defaultValue, open, onRequestClose, title, onRequestConfirm } = props;

  return (
    <Modal show={open} onHide={onRequestClose} size="lg">
      <Form
        validationRules={placeValidationRules}
        onSubmit={onRequestConfirm}
        defaultValue={{
          ...(defaultValue || {}),
          hash: placeToString(defaultValue)
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Icon name="location" className="mr-3" />
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {/* Location picker - autocomplete / map */}
            <Col lg={6}>
              <Form.Field component={LocationGroup} name="" />
            </Col>

            {/* Address details */}
            <Col lg={6}>
              <BForm.Group controlId="locationInput.street">
                <BForm.Label>
                  <I18n id="streetLabel" d="Street" />
                </BForm.Label>

                <Form.Field component={TextInput} name="street" />
              </BForm.Group>

              <BForm.Group controlId="locationInput.city">
                <BForm.Label>
                  <I18n id="cityLabel" d="City" />
                </BForm.Label>

                <Form.Field component={TextInput} name="city" />
              </BForm.Group>

              <BForm.Group controlId="locationInput.postalCode">
                <BForm.Label>
                  <I18n id="postalCodeLabel" d="Zip" />
                </BForm.Label>

                <Form.Field component={TextInput} name="postalCode" />
              </BForm.Group>

              <BForm.Group controlId="locationInput.countryCode">
                <BForm.Label>
                  <I18n id="countryLabel" d="Country" />
                </BForm.Label>

                <Form.Field
                  id="locationInput.countryCode"
                  component={CountryInput}
                  name="countryCode"
                />
              </BForm.Group>

              <FormConsumer>
                {({ data }) =>
                  data.countryCode === 'US' && (
                    <BForm.Group controlId="locationInput.stateCode">
                      <BForm.Label>
                        <I18n id="stateLabel" d="State" />
                      </BForm.Label>

                      <Form.Field
                        component={USStateInput}
                        id="locationInput.state"
                        name="stateCode"
                      />
                    </BForm.Group>
                  )
                }
              </FormConsumer>

              <DataMismatchWarning />
            </Col>
          </Row>
        </Modal.Body>

        {/* modal footer - buttons */}
        <Modal.Footer>
          <Button variant="outline-dark" onClick={onRequestClose}>
            <I18n id="cancelButton" d="Cancel" />
          </Button>
          <Button variant="success" type="submit">
            <I18n id="confirmButton" d="Apply" />
            <Icon name="check" className="ml-3 sole-icon" />
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

// -------------------------------------------------------------------------------------------------

// component is re-rendered only when coords property is changed
// for more info check  <Form.Field name="coords"...
class LocationGroup extends React.Component<*> {
  render(): React.Node {
    const { batchChange, value, name } = this.props;

    return (
      <>
        <BForm.Group controlId="locationFieldset.location">
          <BForm.Label>
            <I18n id="locationLabel" d="Search location" />
          </BForm.Label>
          <I18n id="phonePlaceholder" d="Enter location">
            {placeholder => (
              <GoogleAutocompleteInput
                onChange={changePlace(batchChange)}
                placeholder={placeholder.value}
                value={value}
                name={name}
              />
            )}
          </I18n>
        </BForm.Group>

        <BForm.Group controlId="locationFieldset.map">
          <MapPlacePicker onChange={changePlace(batchChange)} value={value} name={name} />
        </BForm.Group>
      </>
    );
  }

  shouldComponentUpdate(next): boolean {
    return this.props.value.coords !== next.value.coords;
  }
}
// -------------------------------------------------------------------------------------------------

function changePlace(batcher: (*) => void): (OnChangeEvent<Place>) => void {
  return function onChangePlace(event: OnChangeEvent<Place>) {
    let n = {};
    if (event.value) {
      n = {
        ...event.value,
        coords: event.value.coords ? { ...event.value.coords } : { lng: 0, lat: 0 },
        hash: placeToString(event.value)
      };
    }
    batcher(n);
  };
}

function DataMismatchWarning(): React.Node {
  const { data } = React.useContext(FormContext);
  const currentHash = placeToString(data);

  return data.hash !== currentHash ? (
    <p className="text-danger">
      <I18n
        d="Changing the address may result in a discrepancy with the identified GPS coordinates."
        id="locationMismatch"
      />
    </p>
  ) : null;
}
