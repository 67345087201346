// @flow

import * as React from 'react';
import I18n from '_helpers/I18n';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import BBCode from '../../_components/BBCode';
import Layout from '../../_components/Layout';

export default function PaymentError(): React.Node {
  return (
    <Layout verticalCenter>
      <Row className="mt-5 mb-5 d-flex justify-content-center align-items-center">
        <Col lg={4} sm={8} xs={12} className="text-center">
          <Card className="p-5">
            <h1 className="mb-4 mt-4">
              <I18n id="title" d="Ayyy!" />
            </h1>
            <p className="text-center">
              <I18n
                d='Payment process has[br /]been [span class="p-1 bg-danger"]declined[/span][br /]'
                id="message"
              >
                {({ value }) => <BBCode>{value}</BBCode>}
              </I18n>
            </p>
            <p className="text-center mt-3">
              <I18n
                d='Please [a href="/subscribe"]try again[/a][br /]or [a href="/contact"]contact us[/a]'
                id="moreText"
              >
                {({ value }) => <BBCode>{value}</BBCode>}
              </I18n>
            </p>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
}
