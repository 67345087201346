/**
 * @flow
 * @relayHash d856c94c9fbebbc37b489dd92e003e5a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DealCooperation = "RND_COOPERATION" | "SHARE_ASSETS" | "SHARE_PROCUREMENT" | "SPARE_CAPACITY" | "%future added value";
export type DealType = "OFFER" | "REQUEST" | "%future added value";
export type MessagingProvider = "ICQ" | "SIGNAL" | "SKYPE" | "TELEGRAM" | "VIBER" | "WHATSAPP" | "%future added value";
export type companyDetailQueryVariables = {|
  companyId: string
|};
export type companyDetailQueryResponse = {|
  +company: ?{|
    +id: string,
    +name: ?string,
    +logo: ?{|
      +id: string,
      +url: string,
    |},
    +web: ?string,
    +info: ?string,
    +specialization: ?string,
    +certificates: ?string,
    +categories: $ReadOnlyArray<{|
      +id: string,
      +name: string,
    |}>,
    +contact: ?{|
      +firstName: string,
      +lastName: string,
      +phone: ?string,
      +email: string,
      +linkedin: ?string,
      +messaging: $ReadOnlyArray<{|
        +type: MessagingProvider,
        +value: string,
      |}>,
    |},
    +created: ?any,
    +succeededContracts: number,
    +totalContracts: number,
    +vat: ?string,
    +country: ?any,
    +state: ?string,
    +city: ?string,
    +street: ?string,
    +zip: ?string,
    +gps: ?{|
      +lng: number,
      +lat: number,
    |},
    +deals: {|
      +edges: $ReadOnlyArray<{|
        +cursor: number,
        +node: {|
          +id: string,
          +summary: string,
          +type: DealType,
          +cooperation: DealCooperation,
          +description: string,
          +keywords: $ReadOnlyArray<any>,
          +category: {|
            +id: string,
            +name: string,
          |},
          +created: any,
        |},
      |}>,
      +total: number,
      +pageInfo: {|
        +hasPreviousPage: boolean,
        +hasNextPage: boolean,
        +startCursor: ?number,
        +endCursor: ?number,
      |},
    |},
  |}
|};
export type companyDetailQuery = {|
  variables: companyDetailQueryVariables,
  response: companyDetailQueryResponse,
|};
*/

/*
query companyDetailQuery(
  $companyId: ID!
) {
  company(id: $companyId) {
    id
    name
    logo {
      id
      url
    }
    web
    info
    specialization
    certificates
    categories {
      id
      name
    }
    contact {
      firstName
      lastName
      phone
      email
      linkedin
      messaging {
        type
        value
        id
      }
      id
    }
    created
    succeededContracts
    totalContracts
    vat
    country
    state
    city
    street
    zip
    gps {
      lng
      lat
    }
    deals(open: true, first: 20) {
      edges {
        cursor
        node {
          id
          summary
          type
          cooperation
          description
          keywords
          category {
            id
            name
          }
          created
        }
      }
      total
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
}
*/

const node /*: ConcreteRequest*/ = (function() {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'companyId',
        type: 'ID!',
        defaultValue: null
      }
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'id',
        variableName: 'companyId'
      }
    ],
    v2 = {
      kind: 'ScalarField',
      alias: null,
      name: 'id',
      args: null,
      storageKey: null
    },
    v3 = {
      kind: 'ScalarField',
      alias: null,
      name: 'name',
      args: null,
      storageKey: null
    },
    v4 = {
      kind: 'LinkedField',
      alias: null,
      name: 'logo',
      storageKey: null,
      args: null,
      concreteType: 'File',
      plural: false,
      selections: [
        (v2 /*: any*/),
        {
          kind: 'ScalarField',
          alias: null,
          name: 'url',
          args: null,
          storageKey: null
        }
      ]
    },
    v5 = {
      kind: 'ScalarField',
      alias: null,
      name: 'web',
      args: null,
      storageKey: null
    },
    v6 = {
      kind: 'ScalarField',
      alias: null,
      name: 'info',
      args: null,
      storageKey: null
    },
    v7 = {
      kind: 'ScalarField',
      alias: null,
      name: 'specialization',
      args: null,
      storageKey: null
    },
    v8 = {
      kind: 'ScalarField',
      alias: null,
      name: 'certificates',
      args: null,
      storageKey: null
    },
    v9 = [(v2 /*: any*/), (v3 /*: any*/)],
    v10 = {
      kind: 'LinkedField',
      alias: null,
      name: 'categories',
      storageKey: null,
      args: null,
      concreteType: 'NACECategory',
      plural: true,
      selections: (v9 /*: any*/)
    },
    v11 = {
      kind: 'ScalarField',
      alias: null,
      name: 'firstName',
      args: null,
      storageKey: null
    },
    v12 = {
      kind: 'ScalarField',
      alias: null,
      name: 'lastName',
      args: null,
      storageKey: null
    },
    v13 = {
      kind: 'ScalarField',
      alias: null,
      name: 'phone',
      args: null,
      storageKey: null
    },
    v14 = {
      kind: 'ScalarField',
      alias: null,
      name: 'email',
      args: null,
      storageKey: null
    },
    v15 = {
      kind: 'ScalarField',
      alias: null,
      name: 'linkedin',
      args: null,
      storageKey: null
    },
    v16 = {
      kind: 'ScalarField',
      alias: null,
      name: 'type',
      args: null,
      storageKey: null
    },
    v17 = {
      kind: 'ScalarField',
      alias: null,
      name: 'value',
      args: null,
      storageKey: null
    },
    v18 = {
      kind: 'ScalarField',
      alias: null,
      name: 'created',
      args: null,
      storageKey: null
    },
    v19 = {
      kind: 'ScalarField',
      alias: null,
      name: 'succeededContracts',
      args: null,
      storageKey: null
    },
    v20 = {
      kind: 'ScalarField',
      alias: null,
      name: 'totalContracts',
      args: null,
      storageKey: null
    },
    v21 = {
      kind: 'ScalarField',
      alias: null,
      name: 'vat',
      args: null,
      storageKey: null
    },
    v22 = {
      kind: 'ScalarField',
      alias: null,
      name: 'country',
      args: null,
      storageKey: null
    },
    v23 = {
      kind: 'ScalarField',
      alias: null,
      name: 'state',
      args: null,
      storageKey: null
    },
    v24 = {
      kind: 'ScalarField',
      alias: null,
      name: 'city',
      args: null,
      storageKey: null
    },
    v25 = {
      kind: 'ScalarField',
      alias: null,
      name: 'street',
      args: null,
      storageKey: null
    },
    v26 = {
      kind: 'ScalarField',
      alias: null,
      name: 'zip',
      args: null,
      storageKey: null
    },
    v27 = {
      kind: 'LinkedField',
      alias: null,
      name: 'gps',
      storageKey: null,
      args: null,
      concreteType: 'Gps',
      plural: false,
      selections: [
        {
          kind: 'ScalarField',
          alias: null,
          name: 'lng',
          args: null,
          storageKey: null
        },
        {
          kind: 'ScalarField',
          alias: null,
          name: 'lat',
          args: null,
          storageKey: null
        }
      ]
    },
    v28 = {
      kind: 'LinkedField',
      alias: null,
      name: 'deals',
      storageKey: 'deals(first:20,open:true)',
      args: [
        {
          kind: 'Literal',
          name: 'first',
          value: 20
        },
        {
          kind: 'Literal',
          name: 'open',
          value: true
        }
      ],
      concreteType: 'DealsConnection',
      plural: false,
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'edges',
          storageKey: null,
          args: null,
          concreteType: 'DealEdge',
          plural: true,
          selections: [
            {
              kind: 'ScalarField',
              alias: null,
              name: 'cursor',
              args: null,
              storageKey: null
            },
            {
              kind: 'LinkedField',
              alias: null,
              name: 'node',
              storageKey: null,
              args: null,
              concreteType: 'Deal',
              plural: false,
              selections: [
                (v2 /*: any*/),
                {
                  kind: 'ScalarField',
                  alias: null,
                  name: 'summary',
                  args: null,
                  storageKey: null
                },
                (v16 /*: any*/),
                {
                  kind: 'ScalarField',
                  alias: null,
                  name: 'cooperation',
                  args: null,
                  storageKey: null
                },
                {
                  kind: 'ScalarField',
                  alias: null,
                  name: 'description',
                  args: null,
                  storageKey: null
                },
                {
                  kind: 'ScalarField',
                  alias: null,
                  name: 'keywords',
                  args: null,
                  storageKey: null
                },
                {
                  kind: 'LinkedField',
                  alias: null,
                  name: 'category',
                  storageKey: null,
                  args: null,
                  concreteType: 'NACECategory',
                  plural: false,
                  selections: (v9 /*: any*/)
                },
                (v18 /*: any*/)
              ]
            }
          ]
        },
        {
          kind: 'ScalarField',
          alias: null,
          name: 'total',
          args: null,
          storageKey: null
        },
        {
          kind: 'LinkedField',
          alias: null,
          name: 'pageInfo',
          storageKey: null,
          args: null,
          concreteType: 'PageInfo',
          plural: false,
          selections: [
            {
              kind: 'ScalarField',
              alias: null,
              name: 'hasPreviousPage',
              args: null,
              storageKey: null
            },
            {
              kind: 'ScalarField',
              alias: null,
              name: 'hasNextPage',
              args: null,
              storageKey: null
            },
            {
              kind: 'ScalarField',
              alias: null,
              name: 'startCursor',
              args: null,
              storageKey: null
            },
            {
              kind: 'ScalarField',
              alias: null,
              name: 'endCursor',
              args: null,
              storageKey: null
            }
          ]
        }
      ]
    };
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'companyDetailQuery',
      type: 'Query',
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'company',
          storageKey: null,
          args: (v1 /*: any*/),
          concreteType: 'Company',
          plural: false,
          selections: [
            (v2 /*: any*/),
            (v3 /*: any*/),
            (v4 /*: any*/),
            (v5 /*: any*/),
            (v6 /*: any*/),
            (v7 /*: any*/),
            (v8 /*: any*/),
            (v10 /*: any*/),
            {
              kind: 'LinkedField',
              alias: null,
              name: 'contact',
              storageKey: null,
              args: null,
              concreteType: 'User',
              plural: false,
              selections: [
                (v11 /*: any*/),
                (v12 /*: any*/),
                (v13 /*: any*/),
                (v14 /*: any*/),
                (v15 /*: any*/),
                {
                  kind: 'LinkedField',
                  alias: null,
                  name: 'messaging',
                  storageKey: null,
                  args: null,
                  concreteType: 'MessageIdentity',
                  plural: true,
                  selections: [(v16 /*: any*/), (v17 /*: any*/)]
                }
              ]
            },
            (v18 /*: any*/),
            (v19 /*: any*/),
            (v20 /*: any*/),
            (v21 /*: any*/),
            (v22 /*: any*/),
            (v23 /*: any*/),
            (v24 /*: any*/),
            (v25 /*: any*/),
            (v26 /*: any*/),
            (v27 /*: any*/),
            (v28 /*: any*/)
          ]
        }
      ]
    },
    operation: {
      kind: 'Operation',
      name: 'companyDetailQuery',
      argumentDefinitions: (v0 /*: any*/),
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'company',
          storageKey: null,
          args: (v1 /*: any*/),
          concreteType: 'Company',
          plural: false,
          selections: [
            (v2 /*: any*/),
            (v3 /*: any*/),
            (v4 /*: any*/),
            (v5 /*: any*/),
            (v6 /*: any*/),
            (v7 /*: any*/),
            (v8 /*: any*/),
            (v10 /*: any*/),
            {
              kind: 'LinkedField',
              alias: null,
              name: 'contact',
              storageKey: null,
              args: null,
              concreteType: 'User',
              plural: false,
              selections: [
                (v11 /*: any*/),
                (v12 /*: any*/),
                (v13 /*: any*/),
                (v14 /*: any*/),
                (v15 /*: any*/),
                {
                  kind: 'LinkedField',
                  alias: null,
                  name: 'messaging',
                  storageKey: null,
                  args: null,
                  concreteType: 'MessageIdentity',
                  plural: true,
                  selections: [(v16 /*: any*/), (v17 /*: any*/), (v2 /*: any*/)]
                },
                (v2 /*: any*/)
              ]
            },
            (v18 /*: any*/),
            (v19 /*: any*/),
            (v20 /*: any*/),
            (v21 /*: any*/),
            (v22 /*: any*/),
            (v23 /*: any*/),
            (v24 /*: any*/),
            (v25 /*: any*/),
            (v26 /*: any*/),
            (v27 /*: any*/),
            (v28 /*: any*/)
          ]
        }
      ]
    },
    params: {
      operationKind: 'query',
      name: 'companyDetailQuery',
      id: null,
      text:
        'query companyDetailQuery(\n  $companyId: ID!\n) {\n  company(id: $companyId) {\n    id\n    name\n    logo {\n      id\n      url\n    }\n    web\n    info\n    specialization\n    certificates\n    categories {\n      id\n      name\n    }\n    contact {\n      firstName\n      lastName\n      phone\n      email\n      linkedin\n      messaging {\n        type\n        value\n        id\n      }\n      id\n    }\n    created\n    succeededContracts\n    totalContracts\n    vat\n    country\n    state\n    city\n    street\n    zip\n    gps {\n      lng\n      lat\n    }\n    deals(open: true, first: 20) {\n      edges {\n        cursor\n        node {\n          id\n          summary\n          type\n          cooperation\n          description\n          keywords\n          category {\n            id\n            name\n          }\n          created\n        }\n      }\n      total\n      pageInfo {\n        hasPreviousPage\n        hasNextPage\n        startCursor\n        endCursor\n      }\n    }\n  }\n}\n',
      metadata: {}
    }
  };
})();
// prettier-ignore
(node/*: any*/).hash = 'dbcf6d142d90126d22ab0f47496b62be';
module.exports = node;
