// @flow

import idx from 'idx';
import * as React from 'react';
import I18n from '_helpers/I18n';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Redirect from 'react-router-dom/Redirect';

import Link from '_components/Link';
import toastr from '_helpers/toastr';
import BBCode from '_components/BBCode';
import Layout from '_components/Layout';
import DateTime from '_components/DateTime';
import { Query, AuthApi } from '_helpers/Api';
import LoadingContent from '../_components/LoadingContent';
import { Context as IdentitContext } from '_helpers/Identity';

export default function ProfilePage(): React.Node {
  const { identity } = React.useContext(IdentitContext);

  if (!identity) {
    return <Redirect to="/" />;
  }

  const free = !identity.company.paying;

  return (
    <Layout.Auth>
      <Row className="justify-content-center">
        <Col xs={12} sm={12} md={12}>
          <Card className="p-5" style={{ minWidth: '100%' }}>
            <h2>
              <I18n id="title" d="My account" />
            </h2>

            <Row className="mt-4 mb-4 text-left">
              <Col xs={12} sm={6} className="mb-2">
                <I18n id="statusTitle" d="Account status" />
              </Col>
              <Col xs={12} sm={6} className="mb-2">
                <Badge variant={free ? 'danger' : 'success'}>
                  {free ? (
                    <I18n id="freeStatus" d="Free" />
                  ) : (
                    <I18n id="premiumStatus" d="Premium" />
                  )}
                </Badge>
              </Col>
              {!free && (
                <Query name="me">
                  {({ data, loading, retry }) => {
                    const subscription = idx(data, _ => _.me.company.subscription) || null;

                    if (!subscription || loading) {
                      return <LoadingContent min={100} />;
                    }

                    let nextCharge = new Date(subscription.created);
                    if (subscription.type === 'ANNUAL') {
                      nextCharge.setFullYear(nextCharge.getFullYear() + 1);
                    } else if (subscription.type === 'MONTHLY') {
                      const now = new Date();
                      nextCharge.setFullYear(now.getFullYear());
                      nextCharge.setMonth(now.getMonth() + 1);
                    } else {
                      nextCharge = null;
                    }

                    const unsubscribed = !!subscription.unsubscribed;

                    return (
                      <>
                        <Col xs={12} sm={6} className="mb-2">
                          <I18n id="typeTitle" d="Subscription type" />
                        </Col>
                        <Col xs={12} sm={6} className="mb-2">
                          <I18n
                            d="{type, select, ANNUAL{Yearly} MONTHLY{Monthly} other{Unknown}}"
                            type={data.me.company.subscription.type}
                            id="typeValue"
                          />
                        </Col>

                        <Col xs={12} sm={6} className="mb-2">
                          <I18n id="paymentNextCharge" d="Next charge" />
                        </Col>
                        <Col xs={12} sm={6} className="mb-2">
                          {nextCharge ? (
                            <DateTime.Date value={nextCharge} />
                          ) : (
                            <I18n id="nextChargeUnknown" d="Unknown" />
                          )}
                        </Col>

                        <Col xs={12} sm={6} className="mb-2">
                          <I18n id="paymentTitle" d="Payment method" />
                        </Col>
                        <Col xs={12} sm={6} className="mb-2">
                          <I18n id="paymentValue" d="Credit card" />
                        </Col>

                        <Col xs={12} className="mt-4">
                          {unsubscribed ? (
                            <p className="text-left text-md-center">
                              <I18n
                                id="unsubscribedText"
                                d='You already requested end of subscription plan, please wait until we process your request. Fell free to [link href="/contact"]contact Us[/link]'
                              >
                                {({ value }) => <BBCode>{value}</BBCode>}
                              </I18n>
                            </p>
                          ) : (
                            <AuthApi action="unsubscribePayment">
                              {(action, { loading }) => (
                                <Button
                                  onClick={getUnsubscribeHandler(action, retry)}
                                  disabled={loading}
                                  variant="danger"
                                  to="/subscribe"
                                  size="sm"
                                >
                                  <I18n id="unSubscribeButton" d="Unsubscribe" />
                                </Button>
                              )}
                            </AuthApi>
                          )}
                        </Col>
                      </>
                    );
                  }}
                </Query>
              )}
            </Row>

            {free && (
              <div>
                <p className="text-left text-md-center mb-4">
                  <I18n
                    id="freeText"
                    d="Become a premium user[br /] to utilize all features on myB3B.com"
                  >
                    {({ value }) => <BBCode>{value}</BBCode>}
                  </I18n>
                </p>
                <Link component={Button} to="/subscribe">
                  <I18n id="subscribeButton" d="Subscribe now" />
                </Link>
              </div>
            )}
          </Card>
        </Col>
      </Row>
    </Layout.Auth>
  );
}

function getUnsubscribeHandler(
  apiAction: null | (() => Promise<*>),
  refetch: ?() => void
): () => void {
  return function handleUnsubscribe(): void {
    apiAction &&
      apiAction().then(res => {
        toastr({
          type: res.ok ? 'success' : 'error',
          message: res.ok ? (
            <I18n
              id="unsubscribeSucceeeded"
              d="Done, your request is recorded, please wait for confirmation message"
            />
          ) : (
            <I18n
              id="unsubscribeFailed"
              d='Ooops, request failed, please try again or contact us [link href="/contact"]directly[/link]'
            >
              {({ value }) => <BBCode>{value}</BBCode>}
            </I18n>
          )
        });
        res.ok && refetch && refetch();
      });
  };
}
