// @flow

import * as React from 'react';
import Route from 'react-router-dom/Route';
import Switch from 'react-router-dom/Switch';

import PaymentRoot from './PaymentRoot';
import PaymentError from './PaymentError';
import PaymentSuccess from './PaymentSuccess';

export default function SubscribePage(props: *): React.Node {
  const { match } = props;
  return (
    <Switch>
      <Route path={match.url + '/success'} component={PaymentSuccess} />
      <Route path={match.url + '/Error'} component={PaymentError} />
      <Route component={PaymentRoot} />
    </Switch>
  );
}
