// @flow

import idx from 'idx';
import I18n from '_helpers/I18n';
import * as React from 'react';
import { graphql } from 'react-relay';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import type { DealsFilterType } from '../_components/SearchResults';

import Link from '../../_components/Link';
import Icon from '../../_components/Icon';
import Enum from '../../_components/Enum';
import Layout from '../../_components/Layout';
import BBCode from '../../_components/BBCode';
import Searchresults from '../_components/SearchResults';
import Form, { ToggleInput, FormContext } from '../../_components/Form';
import { parseFilter, getFilterUrlAction } from '../_helpers/dealUrlFilter';
import MyDealsCollapsibleFilter from './_components/MyDealsCollapsibleFilter';

// -------------------------------------------------------------------------------------------------

const updateFilterUrl = getFilterUrlAction('/account/my-deals');

export default class MyDealsPage extends React.PureComponent<*> {
  initialFilter = parseFilter('');

  constructor(props: *): void {
    super(props);
    this.initialFilter = parseFilter('?' + window.location.search || '');
  }

  render(): React.Node {
    return (
      <Layout.Common>
        <Form defaultValue={this.initialFilter} didChange={updateFilterUrl}>
          <Row className="deal-filter-header mb-4 mt-4">
            <Col className="d-md-flex align-items-center justify-content-between">
              <h1>
                <I18n id="title" d="My Opportunities" />
              </h1>
              <Link
                className="mb-2 ml-2 mb-sm-0"
                component={Button}
                variant="primary"
                to="/deal/new"
                size="sm"
              >
                <Icon name="plus" className="mr-2" />
                <I18n id="createButton" d="Create Opportunity" />
              </Link>
              <div className="flex-column flex-md-row d-sm-flex justify-sm-content-between float-none float-sm-right align-items-center mb-2 mb-sm-0">
                <Form.Field
                  className="mr-0 mb-2 mr-sm-3 mb-sm-0"
                  component={DealTypePicker}
                  variant="secondary"
                  name="type"
                />

                <Form.Field component={ToggleFilter} name="open" className="mb-2 mb-sm-0" />
              </div>
            </Col>
          </Row>

          <MyDealsCollapsibleFilter />

          <Searchresults
            filterDecorator={decorateFilter}
            contexRef={FormContext}
            noResults={NoResults}
            query={myDealsQuery}
            editMode
            mine
          />
        </Form>
      </Layout.Common>
    );
  }
}

// -------------------------------------------------------------------------------------------------

export function DealTypePicker(props: *): React.Node {
  return (
    <Enum.DealType>
      {({ d }) => (
        <ToggleInput
          {...props}
          options={Object.keys(d).map(key => ({
            label: (
              <>
                <Icon name={key.toLowerCase()} className="mr-2 sole-icon" />
                {d[key]}
              </>
            ),
            value: key
          }))}
          size="sm"
        />
      )}
    </Enum.DealType>
  );
}

// -------------------------------------------------------------------------------------------------

function ToggleFilter(props: *): React.Node {
  return (
    <ToggleInput
      {...props}
      options={[{ label: <I18n id="toggleFilterButton" d="Filter" />, value: true }]}
      value={!!props.value}
      disabled={false}
      size="sm"
    />
  );
}

// -------------------------------------------------------------------------------------------------

function decorateFilter(data: *): DealsFilterType {
  // TODO: unify this decorator with deal search
  const categoryOrKeyword = (data && data.categoryOrKeyword) || {};
  const filter = {
    ...data,
    category: categoryOrKeyword.type === 'CATEGORY' ? categoryOrKeyword.value : null,
    keywords: categoryOrKeyword.type === 'KEYWORD' ? [categoryOrKeyword.label] : [],
    location: null
  };

  const coords = idx(data, _ => _.location.place.coords);
  const radius = idx(data, _ => _.location.radius);
  if (coords && radius) {
    filter.location = {
      lng: coords.lng,
      lat: coords.lat,
      radius
    };
  }

  return filter;
}

// -------------------------------------------------------------------------------------------------

function NoResults() {
  return (
    <I18n d='No opportunities were found. [a href="/deal/new"]Create a new one[/a]' id="noResults">
      {({ value }) => <BBCode>{value}</BBCode>}
    </I18n>
  );
}

// -------------------------------------------------------------------------------------------------

const myDealsQuery = graphql`
  query MyDealsPageQuery(
    $cooperation: [DealCooperation!]
    $keywords: [Keyword!]
    $status: DealStatus
    $type: DealType
    $category: ID
    $first: Int!
    $offset: Int
  ) {
    search {
      results: dealsConnection(
        cooperation: $cooperation
        category: $category
        keywords: $keywords
        offset: $offset
        status: $status
        first: $first
        type: $type
        mine: true
      )
        @connection(
          key: "MyDeals_results"
          filters: ["cooperation", "location", "categories", "keywords", "status", "type"]
        ) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            summary
            type
            cooperation
            status
            category {
              id
              name
            }
            keywords
            created
            description
            unseenContracts: contracts(seen: false) {
              total
            }
          }
        }
        total
      }
    }
  }
`;
