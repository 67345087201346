// @flow

import cn from 'classnames';
import * as React from 'react';
import I18n from '_helpers/I18n';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import Link from '../Link';
import Brand from '../Brand';

// -------------------------------------------------------------------------------------------------

export default function Footer(props: *): React.Node {
  const footerLinks: Array<{ path: string, label: React.Node, blank?: boolean }> = [
    {
      label: <I18n id="terms" d="Terms & conditions" />,
      path: `/terms&conditions`
    },
    {
      label: <I18n id="privacy" d="Privacy policy" />,
      path: `/privacy-policy`
    },
    {
      label: <I18n id="contact" d="Contact" />,
      path: '/contact'
    }
  ];

  return (
    <footer className={cn('page-footer')}>
      <div className="container">
        <Row className="d-flex align-items-center">
          <Col xs={12} md={2} className="mb-3 mb-md-0 text-center text-sm-left">
            <Link to="/">
              <Brand size={48} light />
            </Link>
          </Col>
          <Col xs={12} sm={12} md={8} className="text-center">
            {footerLinks.map((link, key) => {
              return (
                <Link key={key} to={link.path} blank={link.blank}>
                  {link.label}
                </Link>
              );
            })}
            {process.env.REACT_APP_STAGE !== 'production' && (
              <small className="ml-3">{`v.${window.version || ''}`}</small>
            )}
          </Col>
        </Row>
        <Row className="text-center mt-3 p-2 copyright">
          <Col>
            <Link to="https://engerau.com" blank>
              <I18n id="copyright" d="Copyright &copy; 2019" />
            </Link>
          </Col>
        </Row>
      </div>
    </footer>
  );
}
