// @flow

import idx from 'idx';
import I18n from '_helpers/I18n';
import * as React from 'react';

import type { OnChangeEvent } from '../../';
import type { FileInterface } from '../FileInput/FileInput';

import Logger from '_helpers/Logger';
import toastr from '../../../../_helpers/toastr';
import uploadFile from '../../../../_helpers/Api/uploadFile';
import { htmlId, getRequestOptions } from '../FileInput/_helpers';
import LoadingContent from '../../../../_pages/_components/LoadingContent'; // TODO: do not import from pages

// -------------------------------------------------------------------------------------------------

export type ImageInputProps = {|
  onChange: (OnChangeEvent<FileInterface>) => void,
  value: null | FileInterface,
  placeholder?: string,
  disabled?: boolean,
  name: string
|};

// -------------------------------------------------------------------------------------------------

const acceptList = ['image/jpg', 'image/jpeg', 'image/png'];

export default class FileInput extends React.PureComponent<ImageInputProps> {
  inputRef = React.createRef<HTMLInputElement>();

  render(): React.Node {
    const { value, disabled, placeholder } = this.props;

    const img = value && !value.error && value.url;

    return (
      <div className="form-conrol image">
        <input
          style={{ position: 'fixed', left: -9999, top: -9999, opacity: 0 }}
          onChange={this.handleSelect}
          accept={acceptList}
          ref={this.inputRef}
          name="file"
          type="file"
        />

        <div
          className="image-wrapper"
          onClick={this.handleOpen}
          title={placeholder}
          style={{
            backgroundImage: img ? `url(${img})` : undefined
          }}
          disabled={disabled}
        >
          {value && !value.error ? (
            value.url ? null : (
              <LoadingContent />
            )
          ) : (
            <span className="placeholder">{placeholder || 'Image'}</span>
          )}
        </div>
      </div>
    );
  }

  // // --------------------------------------------------------------------------------------------

  handleOpen = () => {
    const ref = this.inputRef && this.inputRef.current;
    if (ref) {
      ref.value = '';
      ref.click();
    }
  };

  // //  -------------------------------------------------------------------------------------------

  handleSelect = (e: SyntheticEvent<*>) => {
    const newFiles: ?FileList = e.currentTarget && e.currentTarget.files;

    const max = 1;
    if (!newFiles || newFiles.length === 0) {
      return;
    }

    const filesToAdd: Array<FileInterface> = [];

    if (newFiles && newFiles.length > 0) {
      const actions = [];

      for (let i = 0; i < newFiles.length; i++) {
        const file = newFiles[i];
        actions.push(file);
        filesToAdd.push({
          error: !!(max && actions.length > max),
          htmlId: htmlId(),
          size: file.size,
          name: file.name,
          progress: 0
        });
      }

      this.props.onChange({
        name: this.props.name,
        value: filesToAdd[0]
      });

      const fileEndpoint = (process.env.REACT_APP_API_HOST || '') + '/storage/upload/avatar';

      actions.reduce((prev: Promise<*>, file: FileInterface, key: number) => {
        return prev
          .then(_ => {
            if (!file || filesToAdd[key].error || filesToAdd[key].progress !== 0) {
              return Promise.resolve();
            }
            // $FlowFixMe
            return uploadFile(fileEndpoint, getRequestOptions(file, filesToAdd[key]));
          })
          .catch(e => {
            toastr({
              type: 'error',
              message: (
                <I18n
                  d='Damn! File "{img}" was not saved!'
                  img={filesToAdd[key].name}
                  id="uploadError"
                />
              )
            });
          })
          .then(response => {
            return new Promise(resolve => {
              const { name, onChange } = this.props;
              const res = idx(response, _ => _.data.file);

              const updated = { ...filesToAdd[key] };
              if (!response || !response.ok || !res) {
                updated.error = response && response.code;
                updated.progress = 0;

                if (['BAD_FILE_EXT', 'MAX_FILE_SIZE'].indexOf(updated.error) === -1) {
                  Logger.error('Error uploadig file', response);
                  updated.error = 'GENERAL_ERROR';
                }

                toastr({
                  type: 'error',
                  message: (
                    <I18n
                      d={{
                        BAD_FILE_EXT: 'File {file} has bad meta type.',
                        MAX_FILE_SIZE: 'File {file} is too big.',
                        GENERAL_ERROR: 'File {file} was not uploaded.'
                      }}
                      file={updated.name}
                      v={updated.error}
                      id="uploadError$"
                    />
                  )
                });
              }
              // $FlowFixMe
              updated.url = (res && res.url) || null;
              // $FlowFixMe
              updated.id = (res && res.id) || null;
              const val = updated;

              const event: OnChangeEvent<FileInterface> = {
                value: val,
                name
              };

              resolve();
              onChange(event);
            });
          });
      }, Promise.resolve());
    }
  };
}
