// @flow

import idx from 'idx';
import * as React from 'react';
import { graphql } from 'react-relay';

import type { OnChangeEvent } from '../../_components/Form';

import { fetchQuery } from '../../_helpers/Api';

export default function MyLocationFakeInput(props: {
  onChange: (OnChangeEvent<*>) => void,
  value: *,
  name: string
}): React.Node {
  const { name, onChange, value } = props;
  React.useEffect(() => {
    if (!value) {
      fetchQuery(myLocationQuery)
        .then(data => {
          const place = idx(data, _ => _.me.place);
          place && onChange && onChange({ name, value: place });
        })
        .catch(_ => {});
    }
  }, [name, onChange, value]);

  return null;
}

const myLocationQuery = graphql`
  query MyLocationFakeInputQuery {
    me {
      place: company {
        street
        postalCode: zip
        city
        countryCode: country
        stateCode: state
        coords: gps {
          lng
          lat
        }
      }
    }
  }
`;
