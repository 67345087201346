// @flow

import * as React from 'react';
import ReactDomServer from 'react-dom/server';
import GoogleAutocomplete from 'react-google-autocomplete';

import type { OnChangeEvent } from '../_components/Form';

import googlePlaceParser from '../_helpers/googlePlaceParser';
import Enum from './Enum';

// -------------------------------------------------------------------------------------------------

export type GoogleAutocompleteInputProps = {
  onChange: (OnChangeEvent<Place>) => void,
  value: null | Place,
  name: string
};

// -------------------------------------------------------------------------------------------------

export default function GoogleAutocompleteInput(props: GoogleAutocompleteInputProps): React.Node {
  const { value, name, onChange, ...rest } = props;
  const defaultValue = placeToString(value);

  const [val, setVal] = React.useState(defaultValue);

  React.useEffect(() => {
    const v = value || {};
    const defaultValue = [v.street, `${v.postalCode || ''} ${v.city || ''}`, v.country]
      .filter(i => typeof i === 'string' && i.trim().length > 0)
      .join(', ');
    setVal(defaultValue);
  }, [value]);

  return (
    <GoogleAutocomplete
      {...rest}
      onPlaceSelected={selectedHandler(onChange, setVal, name)}
      onChange={handleWrite(setVal)}
      className="form-control"
      types={['geocode']}
      onKeyDown={devNull}
      value={val || ''}
    />
  );
}

// -------------------------------------------------------------------------------------------------

function handleWrite(setter: string => void): (e: *) => void {
  return function onInputChange(e: *): void {
    setter(e.currentTarget.value);
  };
}

function devNull(e) {
  if (e.keyCode === 13) {
    e.preventDefault();
    return;
  }
  return e;
}

function selectedHandler(
  onChange: (OnChangeEvent<Place>) => void,
  setVal: string => void,
  name: string
) {
  return function handleOnSelectPlace(response: GooglePlace) {
    if (response && response.address_components && response.geometry) {
      const value = googlePlaceParser(response);
      const v: Place = value || {};
      const defaultValue = [v.street, `${v.postalCode || ''} ${v.city || ''}`, v.country]
        .filter(i => i && i.trim())
        .join(', ');
      setVal(defaultValue || '');
      onChange({ value, name });
    } else {
      setVal('');
      onChange({ name, value: null });
    }
  };
}

export function placeToString(place: null | Place) {
  if (!place) {
    return '';
  }

  const country =
    place.countryCode && !place.country
      ? ReactDomServer.renderToString(<Enum.Country v={place.countryCode} />)
      : null;

  return [
    place.street || null,
    `${place.postalCode || ''} ${place.city || ''}`,
    place.country || country
  ]
    .filter(i => typeof i === 'string' && i.trim().length > 0)
    .join(', ');
}
