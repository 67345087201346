// @flow

import * as React from 'react';
import Link from 'react-router-dom/Link';
import BForm from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Redirect from 'react-router-dom/Redirect';
import I18n, { Context as I18nContext } from '_helpers/I18n';

import type { RegisterActionVariables } from '../../_helpers/Api/Auth';

import toastr from '../../_helpers/toastr';
import { AuthApi } from '../../_helpers/Api';
import Layout from '../../_components/Layout';
import { Context as IdentityContext } from '../../_helpers/Identity';
import Form, { TextInput, PasswordInput } from '../../_components/Form';
import rememberLocaleMutation from '../../_helpers/Api/rememberLocaleMutation';

const Identity = IdentityContext.Consumer;

// -------------------------------------------------------------------------------------------------

const signUpValidation = {
  email: { required: true, email: true },
  password: { required: true, minLength: 8, hasDigit: true }
};

export default function SignUpPage(props: *): React.Node {
  const { locale } = React.useContext(I18nContext);

  return (
    <AuthApi action="register">
      {(action, state) => (
        <Layout.Auth>
          <h1 className="mb-4">
            <I18n id="title" d="Create Account" />
          </h1>
          <Identity>
            {({ identity, updateIdentity }) =>
              identity ? (
                <Redirect
                  to={`/account/profile?next-step=/${encodeURIComponent(
                    'account/company?next-step=/deal'
                  )}`}
                />
              ) : (
                <Form
                  onSubmit={handleSubmit(action, updateIdentity, locale)}
                  validationRules={signUpValidation}
                  disabled={state.loading}
                >
                  <BForm.Group>
                    <I18n id="emailPlaceholder" d="Email">
                      {placeholder => (
                        <Form.Field
                          placeholder={placeholder.value}
                          autoComplete="new-email"
                          component={TextInput}
                          name="email"
                          type="email"
                        />
                      )}
                    </I18n>
                  </BForm.Group>
                  <BForm.Group>
                    <I18n id="passwordPlaceholder" d="Password">
                      {placeholder => (
                        // $FlowFixMe
                        <Form.Field
                          placeholder={placeholder.value}
                          autoComplete="new-password"
                          component={PasswordInput}
                          name="password"
                        />
                      )}
                    </I18n>
                  </BForm.Group>
                  <BForm.Group>
                    <Button variant="primary" block type="submit" disabled={state.loading}>
                      <I18n id="submit" d="Register" />
                    </Button>
                  </BForm.Group>
                </Form>
              )
            }
          </Identity>
          <p className="text-left">
            <br />
            <I18n id="alreadyText" d="Don't have an account yet?" />{' '}
            <Link to="/auth/login">
              <I18n id="alreadyLink" d="Log In!" />
            </Link>
          </p>
        </Layout.Auth>
      )}
    </AuthApi>
  );
}

// -------------------------------------------------------------------------------------------------

const handleSubmit = (
  action: ?(RegisterActionVariables) => Promise<*>,
  updateIdentity: (*) => void,
  locale: string
) => (data: *) => {
  if (!action) {
    return;
  }

  action(data).then(response => {
    const user = response.data;

    if (user && user.id && user.company && user.company.id && user.email) {
      // TODO: create general identity decorator
      updateIdentity({
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        company: user.company,
        permissions: user.company.paying ? ['paying', 'registered'] : ['registered'],
        email: user.email,
        id: user.id
      });
      rememberLocaleMutation(locale);
      return;
    }

    toastr({
      type: 'error',
      message: (
        <I18n
          id="err$"
          d={{
            UNKNOWN_ERROR: 'Ooops! Something went wrong.',
            INVALID_PASSWORD: 'Ooops! Invalid password',
            PASSWORD_REQUIRED: 'Password is required!',
            EMAIL_IN_USE: 'Email is already used!',
            INVALID_EMAIL: 'Oops! Invalid email!',
            EMAIL_REQUIRED: 'Email is required!'
          }}
        >
          {({ d }) => {
            return (response && response.code && d[response.code]) || d.UNKNOWN_ERROR;
          }}
        </I18n>
      )
    });
  });
};
