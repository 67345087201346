// @flow

import I18n from '_helpers/I18n';
import * as React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Redirect from 'react-router/Redirect';

import Link from '../../_components/Link';
import Enum from '../../_components/Enum';
import { Query } from '../../_helpers/Api';
import BBCode from '../../_components/BBCode';
import Layout from '../../_components/Layout';
import DateTime from '../../_components/DateTime';
import LoadingContent from '../_components/LoadingContent';
import { Context as IdentityContext } from '../../_helpers/Identity';

// -------------------------------------------------------------------------------------------------

export type DealDetailPageProps = RouteProps<{ dealId: string }>;

// -------------------------------------------------------------------------------------------------

export default function DealDetailPage(props: DealDetailPageProps): React.Node {
  const { identity } = React.useContext(IdentityContext);

  const { match } = props;
  return (
    <Layout.Common narrow>
      <Query name="dealDetail" variables={{ dealId: match.params.dealId }}>
        {({ loading, error, data }) => {
          const deal = data && data.deal;

          if (loading) {
            return <LoadingContent />;
          }

          if (error) {
            return <Redirect to="/error/500" />;
          }

          if (!deal) {
            return <Redirect to="/error/404" />;
          }

          return (
            <Row>
              <Col md={12} lg={9}>
                <Row className="mb-5">
                  <Col>
                    <h1>
                      <I18n id="title" d="Opportunity details">
                        {({ value }) => <BBCode>{value}</BBCode>}
                      </I18n>
                      <br />
                      <span className="text-primary text-thin mt-2 d-block">{deal.summary}</span>
                    </h1>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={4}>
                    <b>
                      <I18n id="type" d="Opportunity type" />
                    </b>
                  </Col>
                  <Col>
                    <Enum.DealType v={deal.type} />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={4}>
                    <b>
                      <I18n id="cooperation" d="Cooperation type" />
                    </b>
                  </Col>
                  <Col>
                    <Enum.Cooperation v={deal.cooperation} />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={4}>
                    <b>
                      <I18n id="category" d="Category" />
                    </b>
                  </Col>
                  <Col>{deal.category && <span>{deal.category.name}</span>}</Col>
                </Row>
                <Row className="mb-3">
                  <Col md={4}>
                    <b>
                      <I18n id="expires" d="Expires" />
                    </b>
                  </Col>
                  <Col>{deal.expires ? <DateTime value={deal.expires} /> : '-'}</Col>
                </Row>
                <Row className="mb-3">
                  <Col md={4}>
                    <b>
                      <I18n id="description" d="Opportunity description" />
                    </b>
                  </Col>
                  <Col className="text-justify">{deal.description}</Col>
                </Row>
                <Row className="mb-3">
                  <Col md={4}>
                    <b>
                      <I18n id="keywords" d="Keywords" />
                    </b>
                  </Col>
                  <Col>
                    {deal.keywords.reduce(
                      (result: string, keyword: string, key: number, array: Array<string>) =>
                        `${result}${keyword ? '#' : ''}${keyword}${
                          array.length > key + 1 ? ' ' : ''
                        }`,
                      ''
                    )}
                  </Col>
                </Row>
                {deal.partnerRequirements && (
                  <Row className="mb-3">
                    <Col md={4}>
                      <b>
                        <I18n id="requirements" d="Partner requirements" />
                      </b>
                    </Col>
                    <Col>{deal.partnerRequirements}</Col>
                  </Row>
                )}
                {identity && identity.company.paying ? (
                  <>
                    <Row className="mb-3">
                      <Col md={4}>
                        <b>
                          <I18n id="attachments" d="Attachments" />
                        </b>
                      </Col>
                      <Col>
                        {deal.attachments.map((attachment, k, a) => (
                          <div key={k + '-' + attachment.id}>
                            <a rel="noopener noreferrer" href={attachment.url} target="_blank">
                              {attachment.name}
                            </a>
                          </div>
                        ))}
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md={4}>
                        <b>
                          <I18n id="video" d="Video" />
                        </b>
                      </Col>
                      <Col>
                        <a href={deal.video} rel="noopener noreferrer" target="_blank">
                          {deal.video}
                        </a>
                      </Col>
                    </Row>
                  </>
                ) : null}

                <Row className="mt-5">
                  <Col>
                    {identity && identity.company.paying ? (
                      <Link
                        to={`/deal/detail/${deal.id}/contact/${deal.company.id}`}
                        component={Button}
                        variant="primary"
                        className="mr-3"
                      >
                        <I18n id="contactCompany" d="Contact company" />
                      </Link>
                    ) : (
                      <Link to="/subscribe" component={Button} variant="primary" className="mr-3">
                        <I18n id="subscribeButton" d="Subscribe" />
                      </Link>
                    )}

                    <Link component={Button} back variant="outline-dark">
                      <I18n id="backButton" d="Back" />
                    </Link>
                  </Col>
                </Row>
              </Col>
            </Row>
          );
        }}
      </Query>
    </Layout.Common>
  );
}
