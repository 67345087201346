// @flow

import idx from 'idx';
import * as React from 'react';
import RLP from 'react-location-picker';

import type { OnChangeEvent } from '../';

import Logger from '../../../_helpers/Logger';
import googlePlaceParser from '../../../_helpers/googlePlaceParser';

export type MapPlacePickerProps = {|
  onChange: (OnChangeEvent<Place>) => void,
  radius?: null | number, // km
  value: null | Place,
  name: string
|};

type Position = {
  lat: number,
  lng: number
};

export default class MapPlacePicker extends React.Component<MapPlacePickerProps> {
  render(): React.Node {
    const { radius, value } = this.props;
    const radiusValue = typeof radius === 'number' && radius > -1 ? radius * 1000 : -1;
    const zoom = getDefaultZoom(radiusValue);

    return (
      <RLP
        circleOptions={{ fillColor: '#2591fe', strokeColor: '#2591fe', strokeWeight: 5 }}
        containerElement={<div className="map-picker" style={{ height: '100%' }} />}
        defaultPosition={(value && value.coords) || { lng: 0, lat: 0 }}
        mapElement={<div style={{ height: '400px' }} />}
        onChange={this.handleLocationChange}
        radius={radiusValue}
        zoom={zoom}
      />
    );
  }

  shouldComponentUpdate(next: MapPlacePickerProps): boolean {
    const a = idx(next, _ => _.value.coords) || {};
    const b = idx(this.props, _ => _.value.coords) || {};
    const update = a.lng !== b.lng || a.lat !== b.lat || this.props.radius !== next.radius;
    return update;
  }

  handleLocationChange = (data: {
    places: Array<*>,
    position: Position,
    address: string
  }): void => {
    const prev = this.props.value && this.props.value.coords;
    const onChange = this.props.onChange;
    const name = this.props.name;
    const curr = data.position;

    if (
      typeof prev === typeof data.position &&
      (prev && prev.lat === curr.lat && prev.lng === curr.lng)
    ) {
      /*
        skip initi = if 'react-location-picker' component gets value on init,
        it will try to find input position, and then hit onchange if its confirmed
        so we want to populate this action only if position is invalid or different
        -> same = skip
      */
      return;
    }

    try {
      if (data && data.places && data.places[0]) {
        const value: null | Place = googlePlaceParser(data.places[0]);
        onChange && onChange({ name, value });
      }
    } catch (e) {
      Logger.error(e);
    }
  };
}

function getDefaultZoom(radius: number): ?number {
  if (radius === -1) {
    return undefined;
  }

  if (radius > 700000) {
    return 5;
  }

  if (radius >= 400000) {
    return 6;
  }

  if (radius >= 200000) {
    return 7;
  }

  if (radius >= 100000) {
    return 8;
  }
}
